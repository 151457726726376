import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Grid, TextField, CircularProgress, FormGroup, FormControlLabel, Checkbox, FormLabel, FormControl, RadioGroup, Radio } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AddIcon from '@mui/icons-material/Add';
import { guardarActaDefuncion, insertarFallecimiento } from 'app/features/BajaPorFallecimiento/bajaFallecimientoApi';
import { getLeyendasAsync } from 'app/features/Leyendas/leyendasSlice';
import { tipoDocumentoList, getTipoDocumentoAsync } from 'app/features/TipoDocumento/tipoDocumentoSlice';
import { estadoCivilList, getEstadoCivilAsync } from 'app/features/EstadoCivil/estadoCilvilSlice';
import { nacionalidadesList, getNacionalidadesAsync } from 'app/features/Nacionalidades/nacionalidadesSlice';
import { obrasSocialesList, getObrasSocialesAsync } from 'app/features/TiposObrasSociales/tiposObrasSocialesSlice';
import { EMPTY_FILE } from 'constants/icons';
import { guardarDatosMatriculas } from 'app/features/Matriculas/matriculasApi';

export const DatosActaDefuncion = ({ datos, bloquearBtn, setBloquearBtn, setDatos, setValue, titulosProfesional, setOcultarTabs, loaderBtnGuardar, setLoaderBtnGuardar, idEstado, setIdEstado, ocultarContenido, setOcultarContenido, ValorTab, setMatriculas}) => {
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            fecDefuncion: datos.bajaFallecimiento[0].FecFallecido === null ? '' : datos.bajaFallecimiento[0].FecFallecido.split("T")[0],
            nroActa: datos.bajaFallecimiento[0].NroActa,
            nrotomo: datos.bajaFallecimiento[0].ActaTomo,
            anio: datos.bajaFallecimiento[0].ActaAnio,
            nroFolio: datos.bajaFallecimiento[0].ActaFolio,
            jurisdiccion: datos.bajaFallecimiento[0].ActaJurisdiccion
        }
    });
    const dispatch = useDispatch();
    const TipoDocumentos = useSelector(tipoDocumentoList);
    const EstadoCivilList = useSelector(estadoCivilList);
    const NacionalidadesList = useSelector(nacionalidadesList);
    const ObrasSociales = useSelector(obrasSocialesList);
    const [leyendas, setLeyendas] = useState([]);
    const [anchoPantalla, setAnchoPantalla] = useState(0);
    const [mostrarAnalitico, setMostrarAnalitico] = useState(0);
    const [bloqueBtnConfirmarInscripcion, setBloqueBtnConfirmarInscripcion] = useState(false);
    const [estaFirmada, setEstaFirmada] = useState(null);
    const [estaCargadoElInforme, setEstaCargadoElInforme] = useState(null);
    const [marcarRadioGroup, setMarcarRadioGroup] = useState(null);
    window.addEventListener("resize", handleResize);
    const informeCargado = datos.adjuntos.find(adj => adj.fileType === "Informe del SINTyS" && adj.fileName !== "");

    function handleResize() {
        if ((window.innerWidth >= 900 && anchoPantalla < 900) || (window.innerWidth <= 900 && anchoPantalla > 900) || anchoPantalla === 0) {
            setAnchoPantalla(window.innerWidth);
        }
    }

    const handleInputChange = (evt) => {
        const data = evt.target.value
        const name = evt.target.name
        const newData = {...datos};
        newData[name]= data

        setDatos(newData);
    }

    const onClickYes = () => {
        setMarcarRadioGroup(true);
        setEstaFirmada(true);
    }

    const onClickNo = () => {
        setEstaFirmada(false);
        setMarcarRadioGroup(false);
    }

    const verificarSiEstaCargadoInforme = () => {
        if(datos.bajaFallecimiento[0].EstaFirmada !== null){
            setEstaFirmada(datos.bajaFallecimiento[0].EstaFirmada);
            setMarcarRadioGroup(datos.bajaFallecimiento[0].EstaFirmada);
        }
        if(datos.bajaFallecimiento[0].EstaFirmada){
            setMarcarRadioGroup(datos.bajaFallecimiento[0].EstaFirmada);
            setEstaCargadoElInforme(true);
        }
        if (informeCargado) {
            setEstaCargadoElInforme(true);
            setEstaFirmada(true);
        }
    }

    const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    const enviarInscripcion = (data, event) => {
        const buttonName = event.nativeEvent.submitter.name;
        const today = new Date().toISOString().split("T")[0];
        if (data.fecDefuncion > today) {
            Swal.fire({
                title: 'Error', html: 'La fecha de defunción no puede ser mayor a la fecha actual.',
                icon: 'error', showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
            });
            return;
        }
        
        setBloqueBtnConfirmarInscripcion(true);
        setLoaderBtnGuardar(true);
        if(buttonName === 'confirmar') {
            const informeVacio = datos.adjuntos.some(adj => adj.fileType === "Informe del SINTyS" && adj.fileName === "");
            if(informeVacio){
                Swal.fire({
                    title: '', html: 'Debe cargar el informe del SINTyS',
                    icon: 'warning', showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
                });
                setBloqueBtnConfirmarInscripcion(false);
                setLoaderBtnGuardar(false);
                return;
            }
            const newData = {...datos};
            newData.bajaFallecimiento[0].EstaFirmada = true;
            setDatos(newData);

            //TODO: No envío el blnPermiteSeguir por lo que es false
            insertarFallecimiento(data).then(value => {
                const parsedData = JSON.parse(value.data);
                setLoaderBtnGuardar(false);
            
                if (parsedData.resultado) {
                    setIdEstado(7);
                    Swal.fire({
                        title: '', html: 'La solicitud del trámite se inició correctamente.',
                        icon: 'success', showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
                    });
                } else {
                    setBloqueBtnConfirmarInscripcion(false);
                    if (parsedData.message) {
                        Swal.fire({
                            title: 'Atención', html: parsedData.message, icon: 'warning', showCloseButton: true, showCancelButton: true, 
                            confirmButtonText: 'Si', cancelButtonText: 'No', allowOutsideClick: true
                        }).then((result) => {
                            if (result.isConfirmed) {
                                //TODO: envio el blnPermiteSeguir en true 
                                setBloqueBtnConfirmarInscripcion(true);
                                setLoaderBtnGuardar(true);
                                const blnPermiteSeguir = true
                                const mensaje = (parsedData.message.includes("Sociedad") || parsedData.message.includes("sociedad")) ? "Profesional Integrante de Sociedad" : parsedData.message
                                insertarFallecimiento(data, blnPermiteSeguir, mensaje).then(value => {
                                    const parsedData = JSON.parse(value.data);
                                    setLoaderBtnGuardar(false);
                                
                                    if (parsedData.resultado) {
                                        setIdEstado(7);
                                        Swal.fire({
                                            title: '', html: 'La solicitud del trámite se inició correctamente.',
                                            icon: 'success', showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
                                        });
                                    } else {
                                        Swal.fire({
                                            title: 'Atención', html: parsedData.message || 'Ocurrió un error al guardar los datos. Por favor vuelva a intentarlo',
                                            icon: 'warning', showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
                                        });
                                    }
                                    setBloqueBtnConfirmarInscripcion(false);
                                    setLoaderBtnGuardar(false);
                                })
                            }
                        });
                    } else {
                        Swal.fire({
                            title: 'Atención', html: 'Ocurrió un error al guardar los datos. Por favor vuelva a intentarlo',
                            icon: 'warning', showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
                        });
                    }

                }
            });

        } else {
            // setEstaFirmada(true);
            const newData = {...datos};
            newData.bajaFallecimiento[0].EstaFirmada = false;
            newData.bajaFallecimiento[0].FecFallecido = data.fecDefuncion;
            newData.bajaFallecimiento[0].FecActa = data.fecDefuncion;
            newData.bajaFallecimiento[0].NroActa = data.nroActa;
            newData.bajaFallecimiento[0].ActaTomo = data.nrotomo;
            newData.bajaFallecimiento[0].ActaFolio = data.nroFolio;
            newData.bajaFallecimiento[0].ActaAnio = data.anio;
            newData.bajaFallecimiento[0].ActaJurisdiccion = data.jurisdiccion;

            newData.adjuntos.push({ fileType: 'Informe del SINTyS', fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true });
            setDatos(newData)
            guardarActaDefuncion({ datos: JSON.stringify(newData),estado: 6, tpo_tramite: 4}).then((value) => {
                setIdEstado(6);
                setBloqueBtnConfirmarInscripcion(false);
                setLoaderBtnGuardar(false);
                Swal.fire({
                    title: '', html: 'Guardado correctamente.',
                    icon: 'success', showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
                })
            })
        }
    }

    useEffect(() => {
        if (ObrasSociales.length === 0) {
            dispatch(getObrasSocialesAsync());
        }

        if (localStorage.userType !== "10" && localStorage.validacionTitulo !== "1") {
            setMostrarAnalitico(1);
        }

        if (localStorage.userType === "10" || localStorage.userType === "0") {
            Swal.fire({
                title: 'Atención', html: 'Verifique que los documentos a adjuntar se visualicen correctamente',
                icon: 'warning', showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
            })
        }

        setAnchoPantalla(window.screen.width);
        window.addEventListener("resize", handleResize);

        dispatch(getLeyendasAsync("Inscripcion_Profesionales")).then((value) => {
            setLeyendas(value.payload.results)
        });

        if (EstadoCivilList.length === 0) {
            dispatch(getEstadoCivilAsync());
        }

        if (TipoDocumentos.length === 0) {
            dispatch(getTipoDocumentoAsync());
        }

        if (NacionalidadesList.length === 0) {
            dispatch(getNacionalidadesAsync());
        }

    }, [])

    // useEffect(() => {
    //     if(datos.bajaFallecimiento[0].EstaFirmada !== null){
    //         setEstaFirmada(datos.bajaFallecimiento[0].EstaFirmada);
    //     }
    // }, [])
    useEffect(() => {
        verificarSiEstaCargadoInforme()
    }, [])
    

    return (
        <Grid>
            <form onSubmit={handleSubmit(enviarInscripcion)}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Fecha de Defunción"
                            type="date"
                            fullWidth
                            {...register("fecDefuncion", { required: true })}
                            error={!!errors.fecDefuncion}
                            helperText={errors.fecDefuncion ? 'Campo requerido' : ''}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ max: new Date().toISOString().split("T")[0] }}
                            disabled={bloqueBtnConfirmarInscripcion || idEstado === 7 || datos.Observado  || datos.bajaFallecimiento[0].FecFallecido !== '' && datos.bajaFallecimiento[0].FecFallecido !== null}
                            variant={(bloqueBtnConfirmarInscripcion || idEstado === 7 || datos.Observado || datos.bajaFallecimiento[0].FecFallecido !== '' && datos.bajaFallecimiento[0].FecFallecido !== null) ? "filled" : "outlined"}
                            onChange={handleInputChange}
                            value={datos.fecDefuncion}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Número de Acta"
                            type="text"
                            fullWidth
                            {...register("nroActa", { required: true })}
                            error={!!errors.nroActa}
                            helperText={errors.nroActa ? 'Campo requerido' : ''}
                            disabled={bloqueBtnConfirmarInscripcion || idEstado === 7 || datos.Observado}
                            variant={(bloqueBtnConfirmarInscripcion || idEstado === 7 || datos.Observado) ? "filled" : "outlined"}
                            onChange={handleInputChange}
                            value={datos.nroActa}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Número de Tomo"
                            type="number"
                            fullWidth
                            {...register("nrotomo", { required: true })}
                            error={!!errors.nrotomo}
                            helperText={errors.nrotomo ? 'Campo requerido' : ''}
                            disabled={bloqueBtnConfirmarInscripcion || idEstado === 7 || datos.Observado}
                            variant={(bloqueBtnConfirmarInscripcion || idEstado === 7 || datos.Observado) ? "filled" : "outlined"}
                            onChange={handleInputChange}
                            value={datos.nrotomo}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Año"
                            type="number"
                            fullWidth
                            {...register("anio", { required: true })}
                            error={!!errors.anio}
                            helperText={errors.anio ? 'Campo requerido' : ''}
                            disabled={bloqueBtnConfirmarInscripcion || idEstado === 7 || datos.Observado}
                            variant={(bloqueBtnConfirmarInscripcion || idEstado === 7 || datos.Observado) ? "filled" : "outlined"}
                            onChange={handleInputChange}
                            value={datos.anio}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Nombre del registro pertinente"
                            fullWidth
                            {...register("jurisdiccion", { required: true })}
                            error={!!errors.jurisdiccion}
                            helperText={errors.jurisdiccion ? 'Campo requerido' : ''}
                            disabled={bloqueBtnConfirmarInscripcion || idEstado === 7 || datos.Observado}
                            variant={(bloqueBtnConfirmarInscripcion || idEstado === 7 || datos.Observado) ? "filled" : "outlined"}
                            onChange={handleInputChange}
                            value={datos.jurisdiccion}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Número de Folio"
                            type="number"
                            fullWidth
                            {...register("nroFolio", { required: true })} 
                            error={!!errors.nroFolio}
                            helperText={errors.nroFolio ? 'Campo requerido' : ''}
                            disabled={bloqueBtnConfirmarInscripcion || idEstado === 7 || datos.Observado}
                            variant={(bloqueBtnConfirmarInscripcion || idEstado === 7 || datos.Observado) ? "filled" : "outlined"}
                            onChange={handleInputChange}
                            value={datos.nroFolio}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                <Grid item md={12} xs={12}>
                        <Grid sx={{ mt: 3 }} container >
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label" sx={{ color: 'black'}}>¿El acta de defunción es original?</FormLabel>
                                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                    <FormControlLabel value="Si" control={<Radio onClick={onClickYes} checked={marcarRadioGroup === true}/>} label="Sí"  disabled={estaCargadoElInforme || datos.bajaFallecimiento[0].EstaFirmada === false }/>
                                    <FormControlLabel value="No" control={<Radio onClick={onClickNo} checked={marcarRadioGroup === false}/> } label="No" disabled={estaCargadoElInforme || datos.bajaFallecimiento[0].EstaFirmada === true }/>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item md={6} xs={6} sx={{ mt: 3 }} container>
                        <Button startIcon={<ArrowBackIosIcon />} onClick={() => setValue("2")} variant="contained" color="error" disabled={bloquearBtn}>
                            Atrás
                        </Button>
                    </Grid>
                    <Grid item md={6} xs={6}>
                        <Grid sx={{ mt: 3 }} container justifyContent="flex-end">
                            <Grid>
                                {
                                    !!estaFirmada  ? 
                                    <Button startIcon={<AddIcon />} variant="contained" disabled={bloqueBtnConfirmarInscripcion || idEstado === 7 || datos.Observado || estaFirmada === null } type="submit" name="confirmar" color="secondary">
                                        {loaderBtnGuardar ? <CircularProgress /> : 'Confirmar tramite'}
                                    </Button>
                                    :
                                    <Button startIcon={<AddIcon />} variant="contained" disabled={bloqueBtnConfirmarInscripcion || idEstado === 7 || datos.Observado || estaFirmada === null || datos.bajaFallecimiento[0].EstaFirmada === false } type="submit" name="guardar" color="secondary">
                                        {loaderBtnGuardar ? <CircularProgress /> : 'Guardar'}
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Grid>
    );
}