import * as React from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { useEffect, useState } from 'react';
import { Button, Card, CardContent, FormGroup, FormControlLabel, Checkbox, Typography, Modal, Grid, Fab, FormControl, RadioGroup, Radio } from '@mui/material';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { makeStyles } from '@mui/styles';
import { useForm } from "react-hook-form";
import { cambiarAlturaBox } from '../funciones/funcionesGlobales';
import { guardarDatosMatriculas, validarDatosMatriculas } from 'app/features/Matriculas/matriculasApi';
import CloseIcon from '@mui/icons-material/Close';
import { getProfesionalValidarCuit } from 'app/features/Profesionales/profesionalesAPI';
import PdfPreCarga from 'components/DDJJ/pdfPreCarga';
import Pdf from 'components/InscripcionTitulosEspeciales/pdf';
import AddIcon from '@mui/icons-material/Add';
import Swal from 'sweetalert2';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { EMPTY_FILE, PDF_FILE, DEL_FILE } from 'constants/icons';
import { getLeyendasAsync } from 'app/features/Leyendas/leyendasSlice';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ModalTerminosyCondiciones from 'components/common/ModalTerminosyCondiciones';

//BUSQUEDA PARA EL PDF

import { tipoDocumentoList, getTipoDocumentoAsync } from 'app/features/TipoDocumento/tipoDocumentoSlice';
import { estadoCivilList, getEstadoCivilAsync } from 'app/features/EstadoCivil/estadoCilvilSlice';
import { nacionalidadesList, getNacionalidadesAsync } from 'app/features/Nacionalidades/nacionalidadesSlice';
import { obrasSocialesList, getObrasSocialesAsync } from 'app/features/TiposObrasSociales/tiposObrasSocialesSlice';

import { ESTADOS_TRAMITE, TIPOS_USUARIO } from 'constants/constants';
import AprobacionAreaExterna from 'components/AprobacionAreaExterna/AprobacionAreaExterna';
import FilePreview from 'components/common/FilePreview';
import {descargarFile} from 'utils/archivosManager';

const useStyles = makeStyles(theme => ({
    color_texto_aclaracion: {
        color: 'gray',
        fontSize: 1
    },
    floatRight: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    pIcon: {
        textAlign: 'center'
    },
    pFileName: {
        textAlign: 'center',
        marginTop: '0',
        fontWeight: 'bold',
        size: '12px'
    },
    imgIcon: {
        width: '40px',
        height: '40px',
        margin: '0px 0px 0px 0px',
        cursor: 'pointer'
    },
    imgIconCargado: {
        width: '40px',
        height: '40px',
        margin: '0px 0px  0px',
        cursor: 'pointer'
    },
    imgIconTexto: {
        width: '40px',
        height: '22px'
    },
    delIcon: {
        background: 'red',
        width: '25px',
        height: '28px',
        borderRadius: '50%',
        position: 'relative',
        cursor: 'pointer',
        marginTop: "10px"
    },

    modalWindow: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: 'rgb(255, 255, 255)',
        border: '2px solid #000',
        padding: '15px',
    }
}));

function orderAdjuntosByFileLoaded(adjuntos) {
    const adjuntosConFile = adjuntos.filter(a => a.file !== null && a.file !== '');
    const adjuntosSinFile = adjuntos.filter(a => a.file === null || a.file === '');
    return [...adjuntosSinFile, ...adjuntosConFile];
}

export const ArchivosAdjuntos = ({ idLegajoTemporal, datos, bloquearBtn, setBloquearBtn, setDatos, setValue, titulosProfesional, setOcultarTabs, loaderBtnGuardar, setLoaderBtnGuardar, idEstado, setIdEstado, aprobacionDocumentacion, setAprobacionDocumentacion, resultadoDerechoInscripcion }) => {
    const [fileUpdType, setFileUpdType] = useState('');
    const [openPreview, setOpenPreview] = useState(false);
    const [selectedFile, setSelectedFile] = useState({});
    const [fechaAprobacionDocumentacion, setFechaAprobacionDocumentacion] = useState(aprobacionDocumentacion?.fechaAprobacionDocumentacion);
    const [nroResolucionAprobacionDocumentacion, setNroResolucionAprobacionDocumentacion] = useState(aprobacionDocumentacion?.nroResolucionAprobacionDocumentacion)
    const dispatch = useDispatch();
    //pasar a la vista del pdf
    const TipoDocumentos = useSelector(tipoDocumentoList);
    const EstadoCivilList = useSelector(estadoCivilList);
    const NacionalidadesList = useSelector(nacionalidadesList);
    const ObrasSociales = useSelector(obrasSocialesList);
    // console.log("ArchivosAdjuntos -> ObrasSociales")

    const [leyendas, setLeyendas] = useState([]);
    const [anchoPantalla, setAnchoPantalla] = useState(0);
    const [mostrarAnalitico, setMostrarAnalitico] = useState(0);
    function handleResize() {


        if ((window.innerWidth >= 900 && anchoPantalla < 900) || (window.innerWidth <= 900 && anchoPantalla > 900) || anchoPantalla === 0) {
            setAnchoPantalla(window.innerWidth);
        }

    }


    window.addEventListener("resize", handleResize);
    useEffect(() => {
        if (ObrasSociales.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getObrasSocialesAsync());
        }

        if (localStorage.userType !== "10" && localStorage.validacionTitulo !== "1") {
            setMostrarAnalitico(1);
        }

        if (datos.Observado && localStorage.userType === "1")
            setBloqueBtnConfirmarInscripcion(true);

        setAnchoPantalla(window.screen.width);
        window.addEventListener("resize", handleResize);

        //llamo a la funcion del Slice
        dispatch(getLeyendasAsync("Inscripcion_Profesionales")).then((value) => {
            setLeyendas(value.payload.results)
        });

        if (localStorage.userType === "0") {
            Swal.fire({
                title: 'Atención',
                html: 'Verifique que los documentos a adjuntar se visualicen correctamente',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            })
        }

        if (EstadoCivilList.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getEstadoCivilAsync());
        }

        if (TipoDocumentos.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getTipoDocumentoAsync());
        }

        if (NacionalidadesList.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getNacionalidadesAsync());
        }

    }, [])

    let blobDDJJ = {};
    let urlDDJJ = "";

    const [open, setOpen] = useState(false);
    const [openConfirmarTerminos, setOpenConfirmacionTerminos] = useState(false);
   
    //metodo para subir archivos
    const changeHandler = (evt) => {

        if (evt.target.files.length >= 1) {
            const tipo = evt.target.files[0].type;
            const size = ((evt.target.files[0].size / 1000) / 1000).toFixed(4);

            if (tipo !== 'application/pdf' && tipo !== 'image/jpeg' && tipo !== 'image/png') {
                Swal.fire({
                    title: 'Atención',
                    html: 'El tipo de archivo no es correcto, se admite unicamente: pdf, jpg, jpeg o png.',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                })
                return false;
            }
            else if (Number(size) > 5) {
                Swal.fire({
                    title: 'Atención',
                    html: 'El tamaño del archivo  no debe superar 5MB.',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                })
                return false;
            }
            else {
                console.log(datos.adjuntos)
                const upload = datos.adjuntos;
                upload.filter(c => c.fileType === fileUpdType)[0].fileName = evt.target.files[0].name;
                upload.filter(c => c.fileType === fileUpdType)[0].extension = tipo;
                upload.filter(c => c.fileType === fileUpdType)[0].file = evt.target.value;
                upload.filter(c => c.fileType === fileUpdType)[0].size = parseFloat(size);
                upload.filter(c => c.fileType === fileUpdType)[0].saved = false;

                const reader = new FileReader();

                reader.onload = () => {

                    
                    if (tipo !== 'application/pdf')
                        upload.filter(c => c.fileType === fileUpdType)[0].url = reader.result;
                    else
                        upload.filter(c => c.fileType === fileUpdType)[0].url = PDF_FILE;

                    upload.filter(c => c.fileType === fileUpdType)[0].file = reader.result;
                    setDatos({
                        ...datos,
                        adjuntos: upload
                    });
                }
                reader.readAsDataURL(evt.target.files[0]);
            }
        }
    }

    const { handleSubmit, formState: { errors } } = useForm();


    const [perteneceOtroConsejo, setPerteneceOtroConsejo] = useState(0);

    const onSubmit = data => {

        let erroresMostrar = "";

        if (datos.Apellido === "" || datos.CuitCuil === ""
            || datos.Email === "" || datos.EstadoCivil === ""
            || datos.Nombre === ""
            || datos.NroDocumento === "" || datos.TipoDocumento === "") {
            erroresMostrar = "<b>Completar todos los campos disponibles.</b>";
        }

        if ((datos.Nacionalidad === "" || datos.ObraSocial === "") && idEstado !== 1 && idEstado !== 3 && idEstado !== 8) {
            erroresMostrar = "<b>Completar todos los campos disponibles.</b>";
        }


        if (datos.titulos.length > 2) {
            Swal.fire({
                title: 'Atención',
                html: 'Solo puede ingresar hasta <b> dos titulos</b>',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            })
            return false;
        }


        if (calcularEdad(datos.Nacimiento) > 100) {
            Swal.fire({
                title: 'Atención',
                html: 'La <b>Fecha de Nacimiento</b> ingresada es incorrecta',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            })
            return false;
        }

        let errorFamiliarEdad = 0;
        if (Array.isArray(datos.familiares)) {
            datos.familiares.map(fam => {
                if (calcularEdad(fam.fechaNacimiento) > 110) {
                    errorFamiliarEdad = 1;
                }

            })
        }


        if (errorFamiliarEdad === 1) {
            Swal.fire({
                title: 'Atención',
                html: 'La <b>Fecha de Nacimiento</b> de un integrante del grupo familiar primario es incorrecta.',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            })
            return false;
        }
        let mostrarErrorDomicilio = 0;
        datos.domicilios.map((domicilio, index) => {
            if ((idEstado === ESTADOS_TRAMITE.DRAFT || idEstado === ESTADOS_TRAMITE.EN_REVISION || idEstado === ESTADOS_TRAMITE.PRE_CARGA_OBSERVACION) && domicilio.TpoDomicilio === 1) {
                if ((domicilio.calle === "" || domicilio.caracteristica === null || (isNaN(domicilio.caracteristica) || isNaN(domicilio.celular))) && mostrarErrorDomicilio === 0) {
                    if (erroresMostrar !== "") {
                        erroresMostrar += ", ";
                    }
                    erroresMostrar += "<b>Domicilios</b>";
                    mostrarErrorDomicilio = 1;
                }
            } else if ((idEstado !== 1 && idEstado !== 3 && idEstado !== 8)) {
                if ((domicilio.calle === "" || domicilio.caracteristica === null || (isNaN(domicilio.caracteristica) || isNaN(domicilio.celular))) && mostrarErrorDomicilio === 0) {
                    if (erroresMostrar !== "") {
                        erroresMostrar += ", ";
                    }
                    erroresMostrar += "<b>Domicilios</b>";
                    mostrarErrorDomicilio = 1;
                }
            }

        })

        let mostrarErrorAdjuntos = 0;

        let errorDdjjEnfermedades = 0;
        let cantidadAdjuntosFaltantes = 0;
        let nombreAdjuntosFaltantes = "";
        if (datos.PerteneceOtroConsejo === null && idEstado !== 1 && idEstado !== 3 && idEstado !== 8) {
            Swal.fire({
                title: 'Atención',
                html: 'Debe indicar si se encuentra matriculado en otro Consejo Profesional',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            })
            setLoaderBtnGuardar(false)
            setBloquearBtn(false);

            return false;
        }
        
        datos.adjuntos.filter(a => a.fileType.replace(/\d/g, "").trim() !== "Detalle de incumbencias profesionales").map((adjunto, index) => {
            //ERRORES MOSTRAR MODAL ADJUNTOS
            if ((adjunto.file === null || adjunto.file === '') && mostrarErrorAdjuntos === 0
                && adjunto.fileType.replace(/\d/g, "").trim() !== "Plan de estudios") {

                if (adjunto.fileType !== "Foto" && 
                    adjunto.fileType.replace(/\d/g, "").trim() !== "Certificado de libre sanción disciplinaria de otros consejos profesionales" &&
                    adjunto.fileType.replace(/\d/g, "").trim() !== "Declaración jurada de enfermedades y patologías preexistentes") {
                    cantidadAdjuntosFaltantes++;

                    if (nombreAdjuntosFaltantes === "") {
                        nombreAdjuntosFaltantes = "<b>" + adjunto.fileType.replace(/\d/g, "").trim() + "</b>";
                    }
                    else {
                        nombreAdjuntosFaltantes += ",<b>" + adjunto.fileType.replace(/\d/g, "").trim() + "</b>";
                    }
                } else if (adjunto.fileType === "Foto" && idEstado !== 1 && idEstado !== 3 && idEstado !== 8) {
                    cantidadAdjuntosFaltantes++;

                    if (nombreAdjuntosFaltantes === "") {
                        nombreAdjuntosFaltantes = "<b>" + adjunto.fileType.replace(/\d/g, "").trim() + "</b>";
                    }
                    else {
                        nombreAdjuntosFaltantes += ",<b>" + adjunto.fileType.replace(/\d/g, "").trim() + "</b>";
                    }
                } else if (adjunto.fileType.replace(/\d/g, "").trim() === "Certificado de libre sanción disciplinaria de otros consejos profesionales"
                    && idEstado !== 1 && idEstado !== 3 && idEstado !== 8 && datos.PerteneceOtroConsejo === 0) {
                    cantidadAdjuntosFaltantes++;

                    if (nombreAdjuntosFaltantes === "") {
                        nombreAdjuntosFaltantes = "<b>" + adjunto.fileType.replace(/\d/g, "").trim() + "</b>";
                    }
                    else {
                        nombreAdjuntosFaltantes += ",<b>" + adjunto.fileType.replace(/\d/g, "").trim() + "</b>";
                    }
                }
            }
            if (calcularEdad(datos.Nacimiento) >= 50 && (idEstado !== 1 && idEstado !== 3 && idEstado !== 8) && (adjunto.file === null || adjunto.file === '') && (adjunto.fileType.replace(/\d/g, "").trim() === "Declaración jurada de enfermedades y patologías preexistentes (para mayores de  años)" || adjunto.fileType.replace(/\d/g, "").trim() === "Declaración jurada de enfermedades y patologías preexistentes"))
                errorDdjjEnfermedades = 1;
        })

        if (cantidadAdjuntosFaltantes > 0 && cantidadAdjuntosFaltantes <= 2) {
            if (erroresMostrar !== "") {
                erroresMostrar += ", ";
            }
            erroresMostrar += nombreAdjuntosFaltantes;
        } else if (cantidadAdjuntosFaltantes > 0) {
            if (erroresMostrar !== "") {
                erroresMostrar += ", ";
            }
            erroresMostrar += "<b>Adjuntos</b>";
        }

        if (errorDdjjEnfermedades === 1) {
            Swal.fire({
                title: 'Atención',
                html: 'Debe cargar   la <b>Declaración jurada de enfermedades y patologías preexistentes</b>',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            })
            return false;
        }


        if (!Array.isArray(datos.titulos) || datos.titulos.length === 0) {
            if (erroresMostrar !== "") {
                erroresMostrar += ",";
            }
            erroresMostrar += "<b>Titulos</b>";
        }

        if (erroresMostrar !== "") {
            Swal.fire({
                title: 'Atención',
                html: 'Debe completar correctamente los siguientes datos:' + erroresMostrar,
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            });
            return false;
        }
        if (datos.NroDocumento.length !== 10) {
            Swal.fire({
                title: 'Atención',
                html: 'El Numero de Documento ingresado no es valido',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            })
            return false;
        }

        let documento = datos.NroDocumento;

        documento = documento.replace(/\./g, '').replace(/[a-z ]/g, "");

        let cuit = datos.CuitCuil;

        cuit = cuit.substring(3);
        cuit = cuit.substring(0, 8);

        if (datos.CuitCuil.length !== 13 || cuit !== documento) {
            Swal.fire({
                title: 'Atención',
                html: 'El CUIT-CUIL ingresado no es valido',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            })
            return false;
        }

        getProfesionalValidarCuit(datos.CuitCuil.replace(/-/gi, '')).then((value) => {
            if (!value.data.response) {
                Swal.fire({
                    title: 'Atención',
                    html: 'El CUIT-CUIL ingresado es invalido',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                });
                return false;
            } else {
                // obtenerResultadoDerechoInscripcion().then(() => {
                // if(!resultadoDerechoInscripcion ){
                //     setValue("6");
                //     cambiarAlturaBox('0px');
                // }else{
                    setOcultarTabs({ display: 'none' });
                    setOcultarContenido(true);
                    cambiarAlturaBox('0px');
                // }
            }
        });

    };

    const guardarDatosInscripcion = () => {

        // console.log(blobDDJJ);
        // return false;

        const size = ((blobDDJJ.size / 1000) / 1000).toFixed(4);

        const reader = new FileReader();
        const upload = datos.adjuntos;

        let nombreDDJJ = 'Declaracion Jurada';

        if (idEstado === ESTADOS_TRAMITE.DRAFT || idEstado === ESTADOS_TRAMITE.PRE_CARGA_OBSERVACION)
            nombreDDJJ = 'SOLICITUD INCORPORACIÓN DE TÍTULO AL LIBRO ESPECIAL DE TÍTULOS HOMOLOGADOS';


        if (upload.filter(c => c.fileType === nombreDDJJ).length === 0) {
            reader.readAsDataURL(blobDDJJ)
            // console.log(blob, reader);
            //upload.filter(c => c.fileType === 'Declaracion Jurada')[0].file = reader.result;
            reader.onload = () => {
                datos.adjuntos.push({ fileType: nombreDDJJ, fileName: nombreDDJJ, extension: 'application/pdf', file: reader.result, size: parseFloat(size), url: PDF_FILE, saved: false });
            }
        } else {

            reader.readAsDataURL(blobDDJJ)
            // console.log(blob, reader);
            //
            reader.onload = () => {
                upload.filter(c => c.fileType === nombreDDJJ)[0].fileName = nombreDDJJ;
                upload.filter(c => c.fileType === nombreDDJJ)[0].extension = 'application/pdf';
                upload.filter(c => c.fileType === nombreDDJJ)[0].size = parseFloat(size);
                upload.filter(c => c.fileType === nombreDDJJ)[0].saved = false;
                upload.filter(c => c.fileType === nombreDDJJ)[0].file = reader.result;
            }

        }


        setOpenConfirmacionTerminos(true);
    }

    const guardarInscripcion = () => {
        setLoaderBtnGuardar(true)
        if (localStorage.userType != TIPOS_USUARIO.SECRETARIO_TECNICO) {

            Swal.fire({
                title: '',
                html: 'Comienza descarga de archivo...',
                icon: 'info',
                showCloseButton: true,
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false
            });
        }
  
        
        localStorage.userType === "0" ? setBloquearBtn(true) : setBloqueBtnConfirmarInscripcion(true)


        if (localStorage.userType === "0") {

            setDatos({
                ...datos,
                Observado: false
            });
        } else {
            let titulosError = '';
            datos.titulos.map(tit => {
                if (tit.codTitulo === 0 || tit.codUniversidad === 0) {
                    if (titulosError === '')
                        titulosError = tit.nombreTitulo;
                    else
                        titulosError += ',' + tit.nombreTitulo;


                }
            });

            if (titulosError !== '') {
                Swal.fire({
                    title: 'Atención',
                    html: 'Debe seleccionar <b>la Universidad</b> y/o el nombre del <b>Titulo</b>',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                });
                setLoaderBtnGuardar(false)
                localStorage.userType === "0" ? setBloquearBtn(false) : setBloqueBtnConfirmarInscripcion(false)
                return false;
            }

        }
        datos.Observado = false;

        switch (idEstado) {
            case 1:
                idEstado = 3;
                break;
            case 3:
                idEstado = 4;
                break;
            case 4:
                idEstado = 11;
                break;
            case 2:
                idEstado = 7;
                break;
            case 8:
                if (localStorage.userType === "1")
                    idEstado = 9;
                else
                    idEstado = 8;

                break;
            case 8 && localStorage.userType === "0":
                idEstado = 8;
                break;
            case 6:
                idEstado = 6;
                break;
            case 9:
                idEstado = 10;
                break;
        }

        guardarDatosMatriculas({ datos: JSON.stringify(datos), estado: idEstado, accion: localStorage.userType == TIPOS_USUARIO.SECRETARIO_TECNICO ? "CONFIRMAR_PRECARGA" : null, tpo_tramite: 9, fechaAprobacionDocumentacion, nroResolucionAprobacionDocumentacion }).then((value) => {
            if (localStorage.userType != TIPOS_USUARIO.SECRETARIO_TECNICO) {
                const estadoArchivoMapping = {
                    [ESTADOS_TRAMITE.DRAFT]: 'DDJJ.pdf',
                    [ESTADOS_TRAMITE.PENDIENTE]: 'DDJJ.pdf',
                    [ESTADOS_TRAMITE.PRE_CARGA_OBSERVACION]: 'DDJJ.pdf',
                    [ESTADOS_TRAMITE.EN_REVISION]: 'DDJJ.pdf',
                    [ESTADOS_TRAMITE.PENDIENTE_REVISION_FINAL_TTEE]: 'SOLICITUD INCORPORACIÓN DE TÍTULO AL LIBRO ESPECIAL DE TÍTULOS HOMOLOGADOS.pdf',
                    [ESTADOS_TRAMITE.FINALIZADA]: 'SOLICITUD INCORPORACIÓN DE TÍTULO AL LIBRO ESPECIAL DE TÍTULOS HOMOLOGADOS.pdf',
                };
                descargarFile(blobDDJJ, estadoArchivoMapping[idEstado] || 'Descarga.pdf');
            }

            setLoaderBtnGuardar(false)

            // if (localStorage.userType === "1") {
            // setOpenConfirmacionTerminos(true);
            // }
            if (value.data.resultado) {
                switch (idEstado) {

                    case 3:
                        setIdEstado(3);
                        break;
                    case 4:
                        setIdEstado(4);
                        break;
                    case 2:
                        setIdEstado(2);
                        break;
                    case 6:
                        setIdEstado(6);
                    case 7:
                        setIdEstado(7);
                        break;
                    case 8 && localStorage.userType === "1":
                        setIdEstado(9);
                        break;
                    case 8 && localStorage.userType === "0":
                        setIdEstado(8);
                        break;
                    case 9:
                        setIdEstado(10);
                        break;
                    case 11:
                        setIdEstado(11);
                        break;
                }

                setOcultarContenido(false);
                cambiarAlturaBox('45px');
                setOcultarTabs({ display: '' });
                if (localStorage.userType === "1") {

                    setBloqueBtnConfirmarInscripcion(true);
                    Swal.fire({
                        title: '',
                        html: 'La solicitud fue enviada correctamente al profesional',
                        icon: 'success',
                        showCloseButton: true,
                        showCancelButton: false,
                        confirmButtonText:
                            'Aceptar',
                        cancelButtonText:
                            'Cancelar',
                        allowOutsideClick: true
                    });

                }

            } else {
                Swal.fire({
                    title: 'Atención',
                    html: value.data.mensaje !== '' && value.data.mensaje !== null && value.data.mensaje.indexOf('illegal character among') < 0 ? value.data.mensaje : value.data.mensaje.indexOf('illegal character among') >= 0 ? 'Archivo no encontrado' : 'Ocurrio un error al guardar los datos. Por favor vuelva a intentarlo',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                })
                localStorage.userType === "0" ? setBloquearBtn(false) : setBloqueBtnConfirmarInscripcion(false)
                return false;
            }

        });
    }

    const [bloqueBtnConfirmarInscripcion, setBloqueBtnConfirmarInscripcion] = useState(false);


    //ENVIA LA INSCRIPCION PARA GUARDAR EN LAS TABLAS DE MATRICULAS
    const enviarInscripcion = () => {

        setBloqueBtnConfirmarInscripcion(true);
        setLoaderBtnGuardar(true);
        let errorDomicilio = 0;
        datos.domicilios.map(dm => {
            if (dm.codLocalidad === "-1") {
                errorDomicilio = 1;
            }
        })

        if (errorDomicilio === 1) {
            setLoaderBtnGuardar(false);
            setBloqueBtnConfirmarInscripcion(false);
            Swal.fire({
                title: 'Atención',
                html: 'Existen domicilios que tienen ingresada la localidad <b> Otros</b>',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            })
            return false;
        }

        validarDatosMatriculas().then(value => {
            setLoaderBtnGuardar(false);

            if (value.data == '' || value.data.result) {
                setIdEstado(7);
                Swal.fire({
                    title: '',
                    html: 'La solicitud de inscripción se inició correctamente.',
                    icon: 'success',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                });
            } else {
                setBloqueBtnConfirmarInscripcion(false);
                Swal.fire({
                    title: 'Atención',
                    html: value.data.mensaje !== '' && value.data.mensaje !== null && value.data.mensaje.indexOf('illegal character among') < 0 ? value.data.mensaje : value.data.mensaje.indexOf('illegal character among') >= 0 ? 'Archivo no encontrado' : 'Ocurrio un error al guardar los datos. Por favor vuelva a intentarlo',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                })
                return false;
            }
        });


    }

    const deleteFile = file => {
        const arrFiles = datos.adjuntos;
        arrFiles.filter(c => c.fileType === file)[0].fileName = '';
        arrFiles.filter(c => c.fileType === file)[0].extension = '';
        arrFiles.filter(c => c.fileType === file)[0].file = null;
        arrFiles.filter(c => c.fileType === file)[0].size = 0;
        arrFiles.filter(c => c.fileType === file)[0].url = EMPTY_FILE;
        arrFiles.filter(c => c.fileType === file)[0].saved = false;
        setDatos({
            ...datos,
            adjuntos: arrFiles
        });

    };

    const hiddenFileInput = React.useRef(null);

    const handleClick = ev => {
        if (bloquearBtn !== true) {
            setFileUpdType(ev.target.name);
            hiddenFileInput.current.click();
        }

    };

    const popitup = (adjunto) => {

        setSelectedFile(adjunto);
        setOpenPreview(true);
    }

    const handleClose = () => setOpenPreview(false);

    const clases = useStyles();
    const handleOpen = () => {

        setOpen(true);
    }

    ///BORRA LOS ADJUNTOS QUE DECIDIO AGREGAR EXTRA
    const eliminarArchivoAdjuntoExtra = (nombreArchivo) => {

        const arrFiles = datos.adjuntos;
        setDatos({
            ...datos,
            adjuntos: arrFiles.filter(c => c.fileType !== nombreArchivo)
        });
    };
    const ordenarAdjuntos = (adjuntos) => {
        adjuntos.map( adj => {
            // console.log(adj)
            if (adj.extension === 'application/pdf') {
                adj.url = PDF_FILE;
            }
        });
        
        const adjuntosDNIs =  adjuntos.filter(a => a.fileType.indexOf('DNI') >= 0);
        const adjuntosFotos =  adjuntos.filter(a => a.fileType.indexOf('Foto') >= 0);
        const adjuntosMinisterio =  adjuntos.filter(a => a.fileType.indexOf('Ministerio') >= 0);
        const adjuntosTitulo = adjuntos.filter(a => a.fileType.search('-') >= 0);
        const adjuntosPlan = adjuntos.filter(a => a.fileType.indexOf('Plan') >= 0);
        const adjuntosDetalle = adjuntos.filter(a => a.fileType.indexOf('Detalle') >= 0);
        const adjuntosAnalitico = adjuntos.filter(a => a.fileType.indexOf('Analítico') >= 0);
        const adjuntosLibreSancion = adjuntos.filter(a => a.fileType.indexOf('sanción') >= 0);
        const adjuntosDDJJSalud = adjuntos.filter(a => a.fileType.indexOf('enfermedades') >= 0);
        const adjuntosDDJJ = adjuntos.filter(a => a.fileType === "Declaracion Jurada");
        const adjuntosSolicitud = adjuntos.filter(a => a.fileType === "SOLICITUD INCORPORACIÓN DE TÍTULO AL LIBRO ESPECIAL DE TÍTULOS HOMOLOGADOS");

        return [
            ...adjuntosDNIs,
            ...adjuntosFotos,
            ...adjuntosMinisterio,
            ...adjuntosTitulo,
            ...adjuntosPlan,
            ...adjuntosDetalle,
            ...adjuntosAnalitico,
            ...adjuntosDDJJSalud,
            ...adjuntosLibreSancion,
            ...adjuntosDDJJ,
            ...adjuntosSolicitud
        ];
    }

    const agregarAdjunto = (data) => {
        let sumarTitulo = 0;
        let detectaNumeros = /\d+/;
        let cantidadNumeros = /\d+/g;
        //  datos.adjuntos.map((tit) => {
        for (let i = 0; i < datos.adjuntos.length; i++) {
            //SI EL ADJUNTO ES IGUAL EN EL NOMBRE PASA SIN IMPORTAR EL NUMERO
            if (data.replace(/[0-9]/g, '').trim() === datos.adjuntos[i].fileType.replace(/[0-9]/g, '').trim()) {

                //ACA INGRESA SI EL NOMBRE ES EXACTAMENTE IGUAL
                if (data === datos.adjuntos[i].fileType && sumarTitulo === 0) {
                    sumarTitulo++;
                } else if (detectaNumeros.test(datos.adjuntos[i].fileType) && detectaNumeros.test(data)) {
                    sumarTitulo++;

                } else if (detectaNumeros.test(datos.adjuntos[i].fileType) && !detectaNumeros.test(data)) {//SI EN EL NOMBRE NO DETECTA NUMEROS
                    sumarTitulo++;
                }
            }
        };

        datos.adjuntos.push({ fileType: data + ' ' + sumarTitulo, fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: false })
        
        setDatos({
            ...datos,
            adjuntos: ordenarAdjuntos(datos.adjuntos)
        })
    }
   
    const [ocultarContenido, setOcultarContenido] = useState(false);

    const cancelarIncripcionPDF = () => {
        setOcultarContenido(false);
        cambiarAlturaBox('45px');
        setOcultarTabs({ display: '' });
    }
    const calcularEdad = (fecha) => {
        let hoy = new Date();
        let cumpleanos = new Date(fecha);
        let edad = hoy.getFullYear() - cumpleanos.getFullYear();
        let m = hoy.getMonth() - cumpleanos.getMonth();

        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
            edad--;
        }

        return edad;
    }

    function contarNumeros(str) {
        const regex = /\d+/g; // expresión regular que busca todas las secuencias de dígitos
        const coincidencias = str.match(regex); // encuentra todas las coincidencias en la cadena
        return coincidencias ? coincidencias.length : 0; // devuelve la cantidad de coincidencias encontradas o cero si no se encontraron
    }

    const validadEliminarAdjuntoExtra = (adjunto, validacion) => {

        let adjuntoEncontro = 0;
        let posicion = 0;
        let regex = /\d+/;

        for (let i = 0; i < datos.adjuntos.length; i++) {
            if (!regex.test(adjunto))
                break;
            if (adjunto.replace(/[0-9]/g, '').trim() === datos.adjuntos[i].fileType.replace(/[0-9]/g, '').trim()) {

                if (adjunto.search('-') !== -1) {

                    if (adjunto === datos.adjuntos[i].fileType && adjuntoEncontro === 0) {

                        adjuntoEncontro++;
                        break;
                    } else if (regex.test(adjunto)) {
                        adjuntoEncontro++;

                    } else if (adjunto === datos.adjuntos[i].fileType) {
                        adjuntoEncontro++;
                        break;
                    }
                } else {
                    adjuntoEncontro++;
                }
            }

        }

        //  console.log(adjunto, adjuntoEncontro, regex.test(adjunto), adjunto.replace(/[0-9]/g, '').trim())
        //SI LA VARIABLE VALIDACION VIENE EN 0 ES PORQUE LA VALIDACION CORRESPONDE A ELIMINAR UN ADJUNTO EXTRA
        return validacion === 0 ? adjuntoEncontro <= 1 ? false : true : adjuntoEncontro <= 1 ? true : false;
    }

    const continuarGuardadoAdjuntos = () => {

        setLoaderBtnGuardar(true)
        setBloquearBtn(true);
        guardarDatosMatriculas({ datos: JSON.stringify(datos), estado: idEstado, tpo_tramite: 9 }).then((value) => {
            setLoaderBtnGuardar(false)
            setBloquearBtn(false);
            setOpenConfirmacionTerminos(false);
            if (value.data.resultado) {

                //if (localStorage.userType === "10") {
                const response = value.data.result;
                let mensajeAlerta = 'Sr/a profesional, si presiona aceptar su solicitud será enviada al Consejo Profesional';
                if(response.idEstado === ESTADOS_TRAMITE.DRAFT || response.idEstado === ESTADOS_TRAMITE.PRE_CARGA_OBSERVACION){
                    mensajeAlerta = 'Resuelta favorablemente la incorporación del título, se le comunicará por correo electrónico y quedará habilitado para iniciar la etapa 2.';
                }

                Swal.fire({
                    title: 'Atención',
                    html: mensajeAlerta,
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                }).then((result) => {

                    if (result.value) {

                        guardarInscripcion()

                    }
                });

            } else {
                Swal.fire({
                    title: 'Atención',
                    html: value.data.mensaje !== '' && value.data.mensaje !== null && value.data.mensaje.indexOf('illegal character among') < 0 ? value.data.mensaje : value.data.mensaje.indexOf('illegal character among') >= 0 ? 'Archivo no encontrado' : 'Ocurrio un error al guardar los datos. Por favor vuelva a intentarlo',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                })
                return false;
            }

        });

    }
    const validarModificacionAdjunto = tipoAdjunto => {
        if (tipoAdjunto !== "Foto" && tipoAdjunto !== "Certificado de libre sanción disciplinaria de otros consejos profesionales" && (idEstado === ESTADOS_TRAMITE.DRAFT || idEstado === ESTADOS_TRAMITE.EN_REVISION || idEstado === ESTADOS_TRAMITE.PRE_CARGA_OBSERVACION)) {

            return true;
        } else if (tipoAdjunto === "Foto" || tipoAdjunto === "Certificado de libre sanción disciplinaria de otros consejos profesionales" && (idEstado !== ESTADOS_TRAMITE.DRAFT && idEstado !== ESTADOS_TRAMITE.EN_REVISION && idEstado !== ESTADOS_TRAMITE.PRE_CARGA_OBSERVACION)) {
            return true;
        } else if (tipoAdjunto === "Declaración jurada de enfermedades y patologías preexistentes" && (idEstado === ESTADOS_TRAMITE.VALIDADO || idEstado === ESTADOS_TRAMITE.PRE_CARGA_VALIDADA)) {
            return true;
        }
        return false;
    }
    function downloadPDF(pdf, nombrePdf) {
        const linkSource = `${pdf}`;
        const downloadLink = document.createElement("a");

        downloadLink.href = linkSource;
        downloadLink.download = nombrePdf;
        downloadLink.click();
    }
    const [mostrarFoto, setMostrarFoto] = useState(idEstado === ESTADOS_TRAMITE.DRAFT || idEstado === ESTADOS_TRAMITE.EN_REVISION || idEstado === ESTADOS_TRAMITE.PRE_CARGA_OBSERVACION ? false : true);

    const esValidacionFinal = !(idEstado === ESTADOS_TRAMITE.PRE_CARGA_OBSERVACION || idEstado === ESTADOS_TRAMITE.EN_REVISION);
    const esPrecarga = (idEstado === ESTADOS_TRAMITE.PRE_CARGA_OBSERVACION || idEstado === ESTADOS_TRAMITE.EN_REVISION);

    let adjuntosOrdenados = null;
    if(datos.adjuntos)
        adjuntosOrdenados = ordenarAdjuntos(datos.adjuntos);
        
    if(idEstado === ESTADOS_TRAMITE.PRE_CARGA_VALIDADA || idEstado === ESTADOS_TRAMITE.VALIDADO || idEstado === ESTADOS_TRAMITE.PENDIENTE_EN_REVISION)
        adjuntosOrdenados = orderAdjuntosByFileLoaded(adjuntosOrdenados);

    console.log(datos.adjuntos)
    return (
        <Grid>
            {ocultarContenido === false ?
                <form onSubmit={handleSubmit(onSubmit)}>

                    <input type="file" name="uploadFile" onChange={changeHandler} style={{ display: 'none' }} ref={hiddenFileInput} />
                    <Typography style={{ fontSize: 14 }} >* <b>Para adjuntar los documentos deberá hacer clic en el ícono <img src={EMPTY_FILE} className={clases.imgIconTexto} />
                        . Si desea adjuntar un documento complementario –vgr. reverso de DNI, reverso de
                        título, etc.- deberá hacer clic en el botón “+”.</b> </Typography>


                    <Grid container spacing={2} sx={{ mt: 0.01 }}  >

                        {adjuntosOrdenados && adjuntosOrdenados.map((adj, index) => {
                            let mostrarAdjuntoDDJJ = 1;
                            if ((adj.fileType.replace(/\d/g, "").trim() === 'SOLICITUD INCORPORACIÓN DE TÍTULO AL LIBRO ESPECIAL DE TÍTULOS HOMOLOGADOS' && idEstado === ESTADOS_TRAMITE.DRAFT) || (adj.fileType.replace(/\d/g, "").trim() === 'Declaracion Jurada' && (idEstado === ESTADOS_TRAMITE.VALIDADO || idEstado === ESTADOS_TRAMITE.PRE_CARGA_VALIDADA)))
                                mostrarAdjuntoDDJJ = 0;

                            if (adj.fileType.replace(/\d/g, "").trim() === "Certificado de libre sanción disciplinaria de otros consejos profesionales" && (idEstado === ESTADOS_TRAMITE.DRAFT || idEstado === ESTADOS_TRAMITE.EN_REVISION || idEstado === ESTADOS_TRAMITE.PRE_CARGA_OBSERVACION))
                                mostrarAdjuntoDDJJ = 0;

                            if (adj.fileType.replace(/\d/g, "").trim() === "Detalle de incumbencias profesionales")
                                mostrarAdjuntoDDJJ = 0;

                            if ((calcularEdad(datos.Nacimiento) < 50 || (idEstado === 1 || idEstado === 3 || idEstado === 8) ) && adj.fileType.replace(/\d/g, "") === "Declaración jurada de enfermedades y patologías preexistentes" )
                                mostrarAdjuntoDDJJ = 0;

                            //(adjunto.fileType.replace(/\d/g, "") === "Resolución del Resolución Ministerio de Educación de la Nación" || adjunto.fileType.replace(/\d/g, "") ==='DNI' || adjunto.fileType.search('-') !== -1)
                            //MUESTRA LA IMAGEN CARGADA    
                            if ((idEstado !== ESTADOS_TRAMITE.PENDIENTE && localStorage.userType !== '1') ||
                                ((idEstado === ESTADOS_TRAMITE.PENDIENTE || idEstado === ESTADOS_TRAMITE.PENDIENTE_EN_REVISION && datos.Observado === false) && adj.file !== null && adj.file !== '' && localStorage.userType === "0") ||
                                (localStorage.userType === "1" && adj.file !== null && adj.file !== ''))
                                if ((adj.file !== null && adj.file !== '' && localStorage.userType === "1") || localStorage.userType === "0")
                                    if ((mostrarFoto && adj.fileType.replace(/\d/g, "").trim() === "Foto") || adj.fileType.replace(/\d/g, "").trim() !== "Foto")
                                        if (mostrarAdjuntoDDJJ === 1)
                                            return (
                                                <Grid item xs={6} md={4} key={index} >
                                                    <Grid align='center' direction="column" container key={index}>
                                                        <Grid item key={index}>
                                                            <p className={clases.pIcon} style={{ whiteSpace: 'pre-wrap', fontSize: adj.fileType.length < 30 ? 17 : 15, margin: 0 }} title={adj.fileType.replace(/\d/g, "") === "Resolución Ministerio de Educación" ? "Resolución del Ministerio de Educación de la Nación que otorga reconocimiento oficial": adj.fileType }>
                                                                <b> {adj.fileType.replace(/\d/g, "") === "Resolución Ministerio de Educación" ? "Resolución del Ministerio de Educación de la Nación y detalle de incumbencias profesionales": adj.fileType}  {adj.fileType.replace(/\d/g, "") === "Resolución Ministerio de Educación" && <br />}
                                                                </b>
                                                            </p>

                                                            {
                                                                (adj.fileType.replace(/\d/g, "").trim() === "DNI" || adj.fileType.search('-') !== -1) &&

                                                                <p className={clases.pIcon} style={{ whiteSpace: 'pre-wrap', fontSize: 13, margin: 0 }}
                                                                    title={adj.fileType}>
                                                                    <b>Anverso y reverso</b>
                                                                </p>
                                                            }

                                                            {adj.fileType.replace(/\d/g, "").trim() === "Certificado de libre sanción disciplinaria de otros consejos profesionales" &&
                                                                <Grid item>
                                                                    {adj.fileType.replace(/[^0-9]/g, "").length === 0 &&
                                                                        <Grid item md={12}>
                                                                            <Typography style={{ fontSize: 12 }}><b>¿Se encuentra matriculado en otro Consejo Profesional?</b> </Typography>
                                                                        </Grid>
                                                                    }
                                                                    <Grid item md={12} xs={12}>

                                                                        <Grid container>
                                                                            {adj.fileType.replace(/[^0-9]/g, "").length === 0 &&
                                                                                <Grid item md={5}>
                                                                                    <FormControl>
                                                                                        <RadioGroup
                                                                                            row
                                                                                            name="position"
                                                                                            value={datos.PerteneceOtroConsejo}
                                                                                        >
                                                                                            <FormControlLabel
                                                                                                value={0}

                                                                                                onChange={e => setDatos({
                                                                                                    ...datos,
                                                                                                    PerteneceOtroConsejo: Number(e.target.value)
                                                                                                })}
                                                                                                disabled={bloquearBtn}
                                                                                                control={<Radio size="small" />}
                                                                                                label="Si"
                                                                                                labelPlacement="start"
                                                                                            />
                                                                                            <FormControlLabel
                                                                                                value={1}
                                                                                                disabled={bloquearBtn}
                                                                                                onChange={e => setDatos({
                                                                                                    ...datos,
                                                                                                    PerteneceOtroConsejo: Number(e.target.value)
                                                                                                })}
                                                                                                control={<Radio size="small" />}
                                                                                                label="No"

                                                                                                labelPlacement="start"
                                                                                            />

                                                                                        </RadioGroup>
                                                                                    </FormControl>

                                                                                </Grid>
                                                                            }
                                                                            <Grid item md={adj.fileType.replace(/[^0-9]/g, "").length === 0 ? 5 : 12}>
                                                                                {/* IMAGEN ELIMINAR ADJUNTO CARGADO */}
                                                                                {adj.file === null || adj.file === '' ?
                                                                                    <img src={EMPTY_FILE} className={clases.imgIcon} alt='Subir archivo' name={adj.fileType} onClick={handleClick} title='Subir archivo' />
                                                                                    :

                                                                                    <img src={adj.url === "" ? adj.file : adj.url} className={clases.imgIconCargado} alt='empty file' title='Ver archivo' onClick={() => popitup(adj)} />
                                                                                }
                                                                                {(localStorage.userType !== "1" && bloquearBtn !== true && adj.file !== null && adj.file !== '') &&

                                                                                    <img src={DEL_FILE} className={clases.delIcon} alt='Quitar archivo' title='Quitar archivo' onClick={() => deleteFile(adj.fileType)} />
                                                                                }

                                                                                <p className={clases.pFileName} style={{ marginBottom: 0, textAlign: "left" }} >{adj.fileName.length >= 15 ? adj.fileName.substring(0, 11) + '...' : adj.fileName}</p>

                                                                                <p className={clases.pIcon} style={{ whiteSpace: 'pre-wrap', marginTop: 0 }} title={adj.fileType}>

                                                                                    {((localStorage.userType !== "1" && bloquearBtn !== true) && adj.fileType.replace(/[^0-9]/g, "").length > 0) && <Button aria-label="edit" onClick={() => eliminarArchivoAdjuntoExtra(adj.fileType)}><DeleteIcon color="error" style={{ fontSize: 30 }} />  </Button>}

                                                                                    {

                                                                                        ((localStorage.userType !== "1" && bloquearBtn !== true) &&
                                                                                            adj.fileType.replace(/[^0-9]/g, "").length === 0
                                                                                        ) &&

                                                                                        <Fab title={"Agregar " + adj.fileType} color="secondary" size="small" variant="contained" aria-label="edit" onClick={() => agregarAdjunto(adj.fileType)}><AddIcon />  </Fab>
                                                                                    }

                                                                                    {adj.extension === "application/pdf" &&
                                                                                        <Fab title={"Descargar " + adj.fileType} sx={{ ml: 2 }} color="" size="small" variant="contained" onClick={() => downloadPDF(adj.file, adj.fileType)}><FileDownloadIcon />  </Fab>

                                                                                    }
                                                                                </p>
                                                                            </Grid>
                                                                        </Grid>



                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                            {adj.fileType.replace(/\d/g, "").trim() !== "Certificado de libre sanción disciplinaria de otros consejos profesionales" &&
                                                                <Grid item >

                                                                    {adj.file !== null && adj.file !== '' ?
                                                                        <img src={adj.url === "" ? adj.file : adj.url} className={clases.imgIcon} alt='empty file' title='Ver archivo' onClick={() => popitup(adj)} />
                                                                        :
                                                                        <img src={EMPTY_FILE} className={clases.imgIcon} alt='Subir archivo' name={adj.fileType} onClick={handleClick} title='Subir archivo' />
                                                                    }

                                                                    {(localStorage.userType !== "1" && bloquearBtn !== true && adj.file !== null && adj.file !== '' && adj.fileType !== "Declaracion Jurada" && adj.fileType !== "SOLICITUD INCORPORACIÓN DE TÍTULO AL LIBRO ESPECIAL DE TÍTULOS HOMOLOGADOS") &&
                                                                        validarModificacionAdjunto(adj.fileType) &&
                                                                        <img src={DEL_FILE} className={clases.delIcon}
                                                                            alt='Quitar archivo' title='Quitar archivo' onClick={() => deleteFile(adj.fileType)} />
                                                                    }


                                                                    <p className={clases.pFileName} style={{ marginBottom: 0 }} >{adj.fileName.length >= 15 ?
                                                                        adj.fileName.substring(0, 11) + '...' : adj.fileName}</p>
                                                                    <p className={clases.pIcon} style={{ whiteSpace: 'pre-wrap', marginTop: 0, marginBottom: 0 }} title={adj.fileType}>

                                                                        {(((localStorage.userType !== "1" && bloquearBtn !== true) && validadEliminarAdjuntoExtra(adj.fileType, 0) && validarModificacionAdjunto(adj.fileType)) && adj.fileType.replace(/[0-9]/g, '').trim() !== "Foto")
                                                                            && <Button aria-label="edit" onClick={() => eliminarArchivoAdjuntoExtra(adj.fileType)}><DeleteIcon color="error" style={{ fontSize: 30 }} />  </Button>
                                                                        }
                                                                        {adj.fileType.replace(/[0-9]/g, '').trim() === "Foto" && (localStorage.userType !== "1" && bloquearBtn !== true && validadEliminarAdjuntoExtra(adj.fileType, 0))
                                                                            && <Button aria-label="edit" onClick={() => eliminarArchivoAdjuntoExtra(adj.fileType)}><DeleteIcon color="error" style={{ fontSize: 30 }} />  </Button>
                                                                        }



                                                                        {
                                                                            ((localStorage.userType !== "1" && bloquearBtn !== true && adj.fileType !== "Declaracion Jurada" && adj.fileType !== "SOLICITUD INCORPORACIÓN DE TÍTULO AL LIBRO ESPECIAL DE TÍTULOS HOMOLOGADOS") &&
                                                                                validadEliminarAdjuntoExtra(adj.fileType, 1) && validarModificacionAdjunto(adj.fileType)
                                                                            ) &&
                                                                            <Fab title={"Agregar " + adj.fileType} color="secondary" size="small" variant="contained" aria-label="edit" onClick={() => agregarAdjunto(adj.fileType)}><AddIcon />  </Fab>
                                                                        }

                                                                        {(adj.extension === "application/pdf" || adj.extension === "image/jpeg" || adj.extension === "image/png" || adj.extension === "image/tiff") &&
                                                                            <Fab title={"Descargar " + adj.fileType} sx={{ ml: 2 }} color="" size="small" variant="contained" onClick={() => downloadPDF(adj.file, adj.fileType)}><FileDownloadIcon />  </Fab>

                                                                        }
                                                                    </p>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            )

                        })}
                    </Grid>

                    <Grid container>
                        <Grid item xs={12}> </Grid>
                        <Grid item md={6} xs={6} sx={{ mt: 3, alignItems: "flex-end" }} container>

                            <Button sx={{ height: "40px" }} startIcon={<ArrowBackIosIcon />} onClick={() => setValue("4")} variant="contained" color="error" >
                                Atras
                            </Button>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Grid sx={{ mt: 3 }} container justifyContent="flex-end">

                                {(localStorage.userType !== "1") &&
                                    <Button endIcon={<ArrowForwardIosIcon />} disabled={bloquearBtn} variant="contained" type="submit" color="secondary">
                                        {loaderBtnGuardar === true ? <CircularProgress /> : ''} Continuar
                                    </Button>
                                }
                                {
                                    localStorage.userType === "1" &&
                                    <Grid display={"flex"} direction={"column"}>
                                        {/* <Button style={{ marginRight: 7 }} startIcon={<ConstructionIcon />} disabled={idEstado === 1 ? true : false} variant="contained" onClick={revisarInscripcion} color="info">
                                            Revisar Inscripción
                                        </Button> */}

                                        {/* || (idEstado === 6 && datos.Observado === false) */}

                                        {(!datos.Observado && !resultadoDerechoInscripcion) && <AprobacionAreaExterna
                                            setAprobacionDocumentacion={setAprobacionDocumentacion}
                                            esPrecarga={esPrecarga}
                                            fechaAprobacionDocumentacion={fechaAprobacionDocumentacion}
                                            setFechaAprobacionDocumentacion={setFechaAprobacionDocumentacion}
                                            nroResolucionAprobacionDocumentacion={nroResolucionAprobacionDocumentacion}
                                            setNroResolucionAprobacionDocumentacion={setNroResolucionAprobacionDocumentacion}
                                            idLegajoTemporal={idLegajoTemporal}
                                            tipoUsuario={localStorage.userType}
                                            aprobacionDocumentacion={aprobacionDocumentacion} />
                                        }

                                        {
                                            esPrecarga && aprobacionDocumentacion.documentacionEnviadaParaRevision &&
                                            <Button
                                                startIcon={<AddIcon />}
                                                variant="contained"
                                                disabled={fechaAprobacionDocumentacion !== null && fechaAprobacionDocumentacion !== '' &&
                                                nroResolucionAprobacionDocumentacion !== null && nroResolucionAprobacionDocumentacion !== '' && (
                                                    idEstado === ESTADOS_TRAMITE.PRE_CARGA_OBSERVACION ||
                                                    idEstado === ESTADOS_TRAMITE.EN_REVISION ||
                                                    idEstado === ESTADOS_TRAMITE.PRE_CARGA_FINALIZADA ||
                                                    idEstado === ESTADOS_TRAMITE.PENDIENTE)
                                                    && bloqueBtnConfirmarInscripcion === false ? false : true}
                                                onClick={guardarInscripcion}
                                                color="secondary">
                                                {/* {loaderBtnGuardar === true ? <CircularProgress /> : ''} {'Continuar Inscripcion'} */}
                                                {loaderBtnGuardar === true ? <CircularProgress /> : ''} {'Continuar Inscripción'}
                                            </Button>
                                        }
                                        {/* {
                                            esValidacionFinal &&
                                            <Grid>
                                               {
                                                (!resultadoDerechoInscripcion ) ?
                                                <Button
                                                    startIcon={<AddIcon />}
                                                    variant="contained"
                                                    disabled={(
                                                        (idEstado === ESTADOS_TRAMITE.PENDIENTE_EN_REVISION && datos.Observado === false) ||
                                                        idEstado === ESTADOS_TRAMITE.PRE_CARGA_OBSERVACION ||
                                                        idEstado === ESTADOS_TRAMITE.EN_REVISION ||
                                                        // idEstado === ESTADOS_TRAMITE.VALIDA ||
                                                        idEstado === ESTADOS_TRAMITE.PRE_CARGA_FINALIZADA ||
                                                        idEstado === ESTADOS_TRAMITE.PENDIENTE ||
                                                        idEstado === ESTADOS_TRAMITE.PENDIENTE_REVISION_FINAL_TTEE)
                                                        && bloqueBtnConfirmarInscripcion === false ? false : true}
                                                    onClick={enviarInscripcion}
                                                    color="secondary">
                                                    {loaderBtnGuardar === true ? <CircularProgress /> : ''} {'Confirmar inscripcióoooon'}
                                                </Button>
                                                :
                                                <Button endIcon={<ArrowForwardIosIcon />} disabled={bloquearBtn} variant="contained" type="submit" color="secondary">
                                                    {loaderBtnGuardar === true ? <CircularProgress /> : ''} Continuar
                                                </Button>
                                               } 
                                            </Grid>
                                        } */}

                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </form >
                : <Grid>

                    <Typography className={clases.color_texto_aclaracion} sx={{ mb: 1 }} >* Verifique que los datos ingresados sean los correctos y luego haga click en el botón aceptar para enviar su solicitud.</Typography>
                    <PDFViewer style={{
                        width: '100%',
                        height: '65vh'
                    }} showToolbar={false}>
                        {idEstado === ESTADOS_TRAMITE.DRAFT || idEstado === ESTADOS_TRAMITE.PRE_CARGA_OBSERVACION ?
                            <PdfPreCarga datos={datos} ObrasSociales={ObrasSociales} titulosProfesional={titulosProfesional} TipoDocumentos={TipoDocumentos} EstadoCivilList={EstadoCivilList} leyendas={leyendas}  muestraDireccion={true} muestraDatosPersonales={true} esAlta={false}/>
                            :
                            <Pdf datos={datos} ObrasSociales={ObrasSociales} titulosProfesional={titulosProfesional} TipoDocumentos={TipoDocumentos} EstadoCivilList={EstadoCivilList} leyendas={leyendas} />
                        }
                    </PDFViewer>

                    {idEstado === ESTADOS_TRAMITE.DRAFT || idEstado === ESTADOS_TRAMITE.PRE_CARGA_OBSERVACION ?
                        <PDFDownloadLink document={<PdfPreCarga datos={datos} ObrasSociales={ObrasSociales} titulosProfesional={titulosProfesional} TipoDocumentos={TipoDocumentos} EstadoCivilList={EstadoCivilList} leyendas={leyendas} muestraDireccion={true} muestraDatosPersonales={true} esAlta={false}/>} fileName="DDJJ.pdf">
                            {({ blob, url, loading, error }) => {

                                blobDDJJ = blob;
                                urlDDJJ = url;

                            }

                            }
                        </PDFDownloadLink>
                        :
                        <PDFDownloadLink document={<Pdf datos={datos} ObrasSociales={ObrasSociales} titulosProfesional={titulosProfesional} TipoDocumentos={TipoDocumentos} EstadoCivilList={EstadoCivilList} leyendas={leyendas} />} fileName="SOLICITUD INCORPORACIÓN DE TÍTULO AL LIBRO ESPECIAL DE TÍTULOS HOMOLOGADOS.pdf">
                            {({ blob, url, loading, error }) => {

                                blobDDJJ = blob;
                                urlDDJJ = url;
                            }

                            }
                        </PDFDownloadLink>
                    }
                    <Grid container>

                        <Grid item md={6} sx={{ mt: 3 }} container>
                            <Button startIcon={<HighlightOffIcon />} onClick={() => cancelarIncripcionPDF()} variant="contained" color="error" >
                                Cancelar
                            </Button>
                        </Grid>
                        <Grid item md={6}>
                            <Grid sx={{ mt: 3 }} container justifyContent="flex-end">

                                <Button startIcon={<CheckIcon />} disabled={bloquearBtn} variant="contained" onClick={() => guardarDatosInscripcion()} color="secondary">
                                    {loaderBtnGuardar === true ? <CircularProgress /> : ''}  Aceptar
                                </Button>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }

            <FilePreview file={selectedFile} onClose={handleClose} opened={openPreview} />

            <ModalTerminosyCondiciones
                onClose={() => setOpenConfirmacionTerminos(false)}
                open={openConfirmarTerminos}
                onSuccess={continuarGuardadoAdjuntos}
            />
        </Grid >
    );
}

