import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Autocomplete, Button, Card, CardContent, Fab, Grid, Modal, TextField, Typography } from '@mui/material';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import SchoolIcon from '@mui/icons-material/School';
import { useForm } from "react-hook-form";
import { guardarDatosMatriculas } from 'app/features/Matriculas/matriculasApi';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useSelector, useDispatch } from 'react-redux';
import { titulosEspecialesList, titulosEspecialesStatus, getTitulosEspecialesAsync } from 'app/features/Titulos/titulosEspecialesSlice';
import { universidadesList, getUniversidadesAsync } from 'app/features/Universidades/universidadesSlice';
import { EMPTY_FILE } from 'constants/icons';
import { ESTADOS_TRAMITE, ADJUNTOS_BASE } from 'constants/constants';


const useStyles = makeStyles(theme => ({
    floatRight: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    centrarContenido: {
        display: 'flex',
        justifyContent: 'center'
    },
    backGroundBox: {
        backgroundColor: '#00b347!important'
    },
    textField: {
        fontSize: 11,
        marginBottom: 5
    },
    deleteFab: {
        backgroundColor: 'red!important'
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4
    },
    modales: {
        overflow: 'scroll',

    },
    color_texto_aclaracion: {
        color: 'gray',
        fontSize: 1
    },
    color_rojo: {
        color: 'red'
    },
    mapContainer: {
        height: '100vh'
    },
    mapElements: {
        height: '100%'
    }
}));

export const Titulos = ({ datos, bloquearBtn, setDatos, setValue, loaderBtnGuardar, bloquearTabs, setLoaderBtnGuardar, setBloquearBtn, idEstado, titulosProfesional, setMatriculas }) => {
    const clases = useStyles();
    const dispatch = useDispatch();
    const [datosTitulo, setDatosTitulo] = useState({
        id: 0,
        nombreTitulo: '',
        codTitulo: 0,
        codUniversidad: 0,
        nombreUniversidad: '',
        fechaUltimaMateria: '',
        entregoTitulo: true,
        fechaEmisionTitulo: '',
        promedio: '',
    });

    const [universidadesObjeto, setUniversidadesObjeto] = useState([]);
    const [open, setOpen] = useState(false);

    const handleClose = () => setOpen(false);

    const Titulos = useSelector(titulosEspecialesList);
    const TitulosEstado = useSelector(titulosEspecialesStatus);
    const Universidades = useSelector(universidadesList);
    const [titulosSelect, setTitulosSelect] = useState({ label: '', value: 0 });
    const [universidadGuardar, setUniversidadGuardar] = useState({ nombreUniversidad: '', codUniversidad: 0 });
    const [tituloGuardar, setTituloGuardar] = useState({ label: '', value: 0 });
    const [editarRegistro, setEditarRegistro] = useState(0);
    const [mostrarGuardar, setMostrarGuardar] = useState({ display: "none" });
    const [editarRegistroIndex, setEditarRegistroIndex] = useState(0);
    const [titulosAgregarAutocomplete, setTitulosAgregarAutocomplete] = useState([]);
    const [modificoTitulo, setModificoTitulo] = useState(false);

    const handleOpen = (index) => {
        setErrorFecha({ display: "none" })

        //si pasa por aca es que esta editando
        if (!isNaN(index)) {

            setDatosTitulo({
                nombreTitulo: titulosAgregados[index].nombreTitulo,
                codTitulo: titulosAgregados[index].codTitulo,
                codUniversidad: titulosAgregados[index].codUniversidad,
                nombreUniversidad: titulosAgregados[index].nombreUniversidad,
                fechaUltimaMateria: titulosAgregados[index].fechaUltimaMateria,
                entregoTitulo: true,
                fechaEmisionTitulo: titulosAgregados[index].fechaEmisionTitulo,
                anioResolucion: titulosAgregados[index].anioResolucion,
                resolucion: titulosAgregados[index].resolucion,
                promedio: titulosAgregados[index].promedio

            })
            setEditarRegistroIndex(index);
            setEditarRegistro(1);
            setUniversidadSeleccionada({
                label: titulosAgregados[index].codUniversidad === 0 ? '' : titulosAgregados[index].nombreUniversidadNuevo,
                value: titulosAgregados[index].codUniversidad
            });
            if (titulosAgregados[index].codTitulo !== 0) {
                let universidadesMostrar = [];
                Titulos.filter(u => u.codTitulo === titulosAgregados[index].codTitulo && u.codUniversidad !== -1).map(
                    unis => {

                        universidadesMostrar.push({ label: unis.universidadNombre.trim(), value: unis.codUniversidad })


                    });
                setUniversidadesObjeto(universidadesMostrar);
            }


            setTitulosSelect({
                label: titulosAgregados[index].codTitulo === 0 ? '' : titulosAgregados[index].nombreTituloNuevo,
                value: titulosAgregados[index].codTitulo
            });


        } else {

            setDatosTitulo({
                id: 0,
                nombreTitulo: '',
                codTitulo: 0,
                codUniversidad: 0,
                nombreUniversidad: '',
                entregoTitulo: true,
                fechaEmisionTitulo: '',
                fechaUltimaMateria: '',
                resolucion: '',
                anioResolucion: ''
            })
            setUniversidadSeleccionada({
                label: '', value: 0
            });
            setTitulosSelect({
                label: '', value: 0
            });
            setEditarRegistro(0);
        }
        //  alert(index);
        setOpen(true);
    }



    let titulosObjeto = [];
    const [universidadSeleccionada, setUniversidadSeleccionada] = useState({ label: '', value: 0 });


    const ingresaUniversidad = (event, value) => {
        if (value !== null) {
            setUniversidadSeleccionada(value);

            setUniversidadGuardar({
                codUniversidad: value.value,
                nombreUniversidad: value.label
            });
        } else {
            setUniversidadSeleccionada({ label: '', value: 0 });
            setUniversidadGuardar({
                codUniversidad: 0,
                nombreUniversidad: ''
            });
        }

    }



    const handleTitulo = (event, value) => {

        if (value !== null) {
            setTituloGuardar({
                codTitulo: value.value,
                nombreTitulo: value.label
            });

            let universidadesMostrar = [];
            Titulos.filter(u => u.codTitulo === value.value && u.codUniversidad !== -1).map(
                unis => {

                    universidadesMostrar.push({ label: unis.universidadNombre.trim(), value: unis.codUniversidad })


                });
            setUniversidadSeleccionada({ label: '', value: 0 });
            setUniversidadesObjeto(universidadesMostrar);
        } else {
            setUniversidadSeleccionada({ label: '', value: 0 });
            setUniversidadesObjeto([]);
            setUniversidadGuardar({
                codUniversidad: 0,
                nombreUniversidad: ''
            });
            setTituloGuardar({
                codTitulo: 0,
                nombreTitulo: ''
            });
        }
    }
    const [btnGuardarTituloEstado, setBtnGuardarTituloEstado] = useState(false)
    const [errorFechas, setErrorFecha] = useState({ display: "none" });
    const [errorFechasTexto, setErrorFechaText] = useState('');
    const inputHandleChange = (evt) => {

        const { name, value } = evt.target;

        if (evt.target.name === "fechaUltimaMateria" || evt.target.name === "fechaEmisionTitulo") {

            var hoy = new Date();
            var fechaFormulario = new Date(evt.target.value);

            // Comparamos solo las fechas => no las horas!!
            hoy.setHours(0, 0, 0, 0);  // Lo iniciamos a 00:00 horas

            if (hoy <= fechaFormulario) {


                var mes = (hoy.getMonth() + 1);
                if ((hoy.getMonth() + 1) < 10) {
                    mes = "0" + (hoy.getMonth() + 1)
                }
                //hoy.getFullYear() + "-" + mes + "-" +  hoy.getDate()
                setDatosTitulo({
                    ...datosTitulo,
                    [evt.target.name]: ''
                });
                setErrorFechaText("La fecha ingresada no debe ser posterior a la fecha actual");
                setErrorFecha({ display: "" })
                return false;
            } else if (evt.target.name === "fechaUltimaMateria") {
                if (datosTitulo.fechaEmisionTitulo !== "") {
                    if (new Date(evt.target.value) > new Date(datosTitulo.fechaEmisionTitulo)) {
                        setErrorFechaText("La fecha última materia no puede  ser posterior a la fecha  emisión de título");
                        setErrorFecha({ display: "" })

                        setDatosTitulo({
                            ...datosTitulo,
                            [evt.target.name]: ''
                        });
                        return false;
                    }
                }
            } else if (datosTitulo.fechaUltimaMateria !== "") {

                if (new Date(evt.target.value) < new Date(datosTitulo.fechaUltimaMateria)) {
                    setErrorFechaText("La fecha última materia no puede  ser posterior a la fecha  emisión de título");
                    setErrorFecha({ display: "" })
                    setBtnGuardarTituloEstado(true)
                    setDatosTitulo({
                        ...datosTitulo,
                        [evt.target.name]: evt.target.value
                    });
                    return false;
                }

            }

        }
        
        if (name === 'promedio') {

            let newValue = value.replace('.', ',');
            
            const regex = /^[0-9]*[.,]?[0-9]*$/;
            
            if (!regex.test(newValue)) {
                setDatosTitulo(prevState => ({
                    ...prevState,
                    [name]: ''
                }));
                return;
            }

            const parts = newValue.split(',');
            if (parts.length > 1) {
                parts[1] = parts[1].slice(0, 2);
                newValue = parts.join(',');
            }
            
            const numericValue = parseFloat(newValue);
            if (numericValue < 1 || numericValue > 10) {
                return; 
            }

            if (numericValue === 10 && newValue.includes(',')) {
                return;
            }
    
            setDatosTitulo(prevState => ({
                ...prevState,
                [name]: newValue
            }));

            return
        }

        setErrorFecha({ display: "none" })


        const regex = /^[0-9\b]+$/;
        if (!regex.test(evt.target.value) && evt.target.name === 'anioResolucion') {
            return false;
        }

        const numbers = /\d/;
        if (evt.target.name === 'nombreTitulo' && numbers.test(evt.target.value)) {
            return false;
        }
        
        setDatosTitulo({
            ...datosTitulo,
            [evt.target.name]: evt.target.value
        });

        setBtnGuardarTituloEstado(false)
    }

    const onTitleNameChange = (evt) => {

        const numbers = /\d/;
        if(numbers.test(evt.target.value)){
            return false;
        }

        setDatosTitulo({
            ...datosTitulo,
            nombreTitulo: evt.target.value
        });

        setModificoTitulo(true);
        setBtnGuardarTituloEstado(false);
    }

    const [anchoPantalla, setAnchoPantalla] = useState(0);

    function handleResize() {


        if ((window.innerWidth >= 900 && anchoPantalla < 900) || (window.innerWidth <= 900 && anchoPantalla > 900) || anchoPantalla === 0) {
            setAnchoPantalla(window.innerWidth);
        }

    }

    window.addEventListener("resize", handleResize);

    useEffect(() => {
        dispatch(getTitulosEspecialesAsync());
        dispatch(getUniversidadesAsync());
    },[]);

    useEffect(() => {
        setUniversidadSeleccionada({ label: '', value: 0 });
        setAnchoPantalla(window.screen.width);
        window.addEventListener("resize", handleResize);
        if (Titulos.length === 0) {

            //llamo a la funcion del Slice
            dispatch(getTitulosEspecialesAsync());
        }
        if (Universidades.length === 0) {

            //llamo a la funcion del Slice
            dispatch(getUniversidadesAsync());
        }

        if (TitulosEstado === 'correcto') {

            const TitulosUnicos = Titulos.filter((item, index) => {
                return index === Titulos.findIndex(t => t.codTitulo === item.codTitulo && t.codUniversidad !== -1);
            });

            TitulosUnicos.map((titulo, index) =>

                titulosObjeto.push({ "label": titulo.tituloNombre.trim(), "value": titulo.codTitulo })
            )

            setTitulosAgregarAutocomplete(titulosObjeto);
        }
    }, [TitulosEstado])

    const [titulosAgregados, setTitulosAgregados] = useState([]);
    const [titulosGuardados, setTitulosGuardados] = useState(0);
    if (titulosGuardados === 0) {


        if (Array.isArray(datos.titulos)) {

            setTitulosAgregados(datos.titulos);
            if (datos.titulos.length > 0) {
                setMostrarGuardar({ display: "" });
            }
        }
        setTitulosGuardados(1);
    }

    const eliminarSegundoNumero = str => {
        const regex = /\d+$/; // expresión regular que busca la última secuencia de dígitos en la cadena
        return str.replace(regex, ''); // reemplaza la última coincidencia por una cadena vacía
    }

    const eliminarAdjuntoTitulo = (id, nombreTitulo) => {
        return datos.adjuntos.filter(adj => adj.id !== id && adj.fileType.replace(/\d/g, "").trim() !== "Título - " + nombreTitulo.replace(/\d/g, "").trim());
    }

    const eliminarTitulo = (index) => {

        Swal.fire({
            title: 'Atención',
            html: '¿Esta seguro que desea eliminar este título?',
            icon: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText:
                'Aceptar',
            cancelButtonText:
                'Cancelar',
            allowOutsideClick: true
        }).then((result) => {

            if (result.value) {

                if (titulosAgregados.length === 1) {
                    setTitulosAgregados([])
                    // setMostrarGuardar({display:"none"});
                } else {
                    setTitulosAgregados(titulosAgregados.filter(item => item.id !== index))
                }
                let arrFiles = [];
                let agregado = 0;

                agregado = 0;
                datos.adjuntos.filter(a => a.fileType.replace(/\d/g, "").trim() === "Detalle de incumbencias profesionales").map((adj) => {
                    if (adj.fileType.replace(/\d/g, "").trim() === "DNI" || adj.fileType.replace(/\d/g, "").trim() === "Resolución Ministerio de Educación" || adj.fileType.replace(/\d/g, "").trim() === "Certificado"
                        || adj.fileType.replace(/\d/g, "").trim() === "Certificado de libre sanción disciplinaria de otros consejos profesionales" || adj.fileType.replace(/\d/g, "").trim() === "Declaracion Jurada"
                        || adj.fileType.replace(/\d/g, "").trim() === "Declaración jurada de enfermedades y patologías preexistentes"
                        || adj.fileType.replace(/\d/g, "").trim() === "Detalle de incumbencias profesionales"
                        || adj.fileType.replace(/\d/g, "").trim() === "Foto"
                        || adj.fileType.replace(/\d/g, "").trim() === "Analítico" || adj.fileType.replace(/\d/g, "").trim() === "Plan de estudios") {
                        arrFiles.push({ fileType: adj.fileType, fileName: adj.fileName, extension: adj.extension, file: adj.file, size: adj.size, url: adj.url });
                    } else {

                        titulosAgregados.filter(item => item.id !== index).map(tit => {
                            if ('Título - ' + tit.nombreTitulo.replace(/\d/g, "").trim() === adj.fileType.replace(/\d/g, "").trim()) {
                                agregado = 1;
                                arrFiles.push({ fileType: adj.fileType, fileName: adj.fileName, extension: adj.extension, file: adj.file, size: adj.size, url: adj.url });
                            }
                        })
                    }

                })


                setDatos({
                    ...datos,
                    titulos: titulosAgregados.filter(item => item.id !== index),
                    adjuntos: arrFiles
                })

            }
        });
    }

    const agregarTituloArray = () => {

        let tituloAnterior = "";
        let tituloNuevo = "";
        //let tituloNuevo = "";
        if (editarRegistro === 1) {
            tituloAnterior = titulosAgregados[editarRegistroIndex].nombreTitulo;
            tituloNuevo = tituloGuardar.nombreTitulo;

            if(modificoTitulo){
                datos.adjuntos = eliminarAdjuntoTitulo(ADJUNTOS_BASE.TITULO.id, tituloAnterior);
                datos.adjuntos.push({id:ADJUNTOS_BASE.TITULO.id , fileType: 'Título - ' + datosTitulo.nombreTitulo.trim(), fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE })
            }

            titulosAgregados[editarRegistroIndex].fechaUltimaMateria = datosTitulo.fechaUltimaMateria;
            titulosAgregados[editarRegistroIndex].entregoTitulo = true;
            titulosAgregados[editarRegistroIndex].fechaEmisionTitulo = datosTitulo.fechaEmisionTitulo;
            titulosAgregados[editarRegistroIndex].anioResolucion = datosTitulo.anioResolucion;
            titulosAgregados[editarRegistroIndex].resolucion = datosTitulo.resolucion;
            titulosAgregados[editarRegistroIndex].promedio = datosTitulo.promedio;

            if (localStorage.userType === '0') {
                titulosAgregados[editarRegistroIndex].nombreTitulo = datosTitulo.nombreTitulo;
                titulosAgregados[editarRegistroIndex].codTitulo = datosTitulo.codTitulo;
                titulosAgregados[editarRegistroIndex].nombreUniversidad = datosTitulo.nombreUniversidad;
                titulosAgregados[editarRegistroIndex].codUniversidad = datosTitulo.codUniversidad;
            } else {

                titulosAgregados[editarRegistroIndex].codUniversidad = universidadGuardar.codUniversidad;
                titulosAgregados[editarRegistroIndex].nombreUniversidadNuevo = universidadGuardar.nombreUniversidad;
                titulosAgregados[editarRegistroIndex].nombreTituloNuevo = tituloGuardar.nombreTitulo;
                titulosAgregados[editarRegistroIndex].codTitulo = tituloGuardar.codTitulo;


            }

            setTitulosAgregados(titulosAgregados)
            setDatos({
                ...datos,
                titulos: titulosAgregados
            })

        } else {
            if (localStorage.userType === '0') {
                let errorTituloCargado = 0;
                for (let i = 0; i < titulosAgregados.length; i++) {
                    if (datosTitulo.nombreTitulo.replace(/[0-9]/g, '').trim() === titulosAgregados[i].nombreTitulo.replace(/[0-9]/g, '').trim()) {
                        errorTituloCargado = 1;
                        break;
                    }
                }
                if (errorTituloCargado === 1) {
                    setErrorFechaText("Ya se encuentra cargado un titulo con ese nombre");
                    setErrorFecha({ display: "" })
                    return false;
                }
            }

            datos.adjuntos.push({ fileType: 'Título - ' + datosTitulo.nombreTitulo.trim(), fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE })

            const listaTitulos = [...titulosAgregados, {
                id: 1 + (Math.random() * (999999 - 1)),
                codUniversidad: datosTitulo.codUniversidad,
                fechaEmisionTitulo: datosTitulo.fechaEmisionTitulo,
                nombreTitulo: datosTitulo.nombreTitulo,
                codTitulo: datosTitulo.codTitulo,
                nombreUniversidad: datosTitulo.nombreUniversidad,
                entregoTitulo: true,
                fechaUltimaMateria: datosTitulo.fechaUltimaMateria,
                anioResolucion: datosTitulo.anioResolucion,
                resolucion: datosTitulo.resolucion,
                promedio: datosTitulo.promedio
            }]

            datos.titulos.push({
                id: 1 + (Math.random() * (999999 - 1)),
                codUniversidad: datosTitulo.codUniversidad,
                fechaEmisionTitulo: datosTitulo.fechaEmisionTitulo,
                nombreTitulo: datosTitulo.nombreTitulo,
                codTitulo: datosTitulo.codTitulo,
                nombreUniversidad: datosTitulo.nombreUniversidad,
                entregoTitulo: true,
                fechaUltimaMateria: datosTitulo.fechaUltimaMateria,
                anioResolucion: datosTitulo.anioResolucion,
                resolucion: datosTitulo.resolucion,
                promedio: datosTitulo.promedio
            })

            setTitulosAgregados(listaTitulos)
        }

        if (localStorage.userType === '1' || editarRegistro === 0)
            actualizarAdjuntos(tituloAnterior, tituloNuevo);

        setMostrarGuardar({ display: "" });
        setOpen(false);
    }


    const actualizarAdjuntos = (tituloAnterior, tituloNuevo) => {
        let adjuntosOrdenados = 0;
        let adjuntosArray = [];
        let titulosTotales = 0;
        let dniTotales = 0;
        let resolucionTotales = 0;
        let analiticoTotales = 0;
        let fotoTotales = 0;
        let libreSancion = 0;
        let detalleTotales = 0;
        let DDJJTotales = 0;
        let planTotales = 0;
        let declaracionJuradaEnfermedadesTotales = 0;

        datos.adjuntos.map((adj, index) => {

            if (adj.fileType.search('-') >= 0)
                titulosTotales++;

            if (adj.fileType.indexOf('DNI') >= 0)
                dniTotales++;

            if (adj.fileType.indexOf('Foto') >= 0)
                fotoTotales++;

            if (adj.fileType.indexOf('Ministerio') >= 0)
                resolucionTotales++;

            if (adj.fileType.indexOf('Plan') >= 0)
                planTotales++;

            if (adj.fileType.indexOf('Analítico') >= 0)
                analiticoTotales++;


            if (adj.fileType.indexOf('sanción') >= 0)
                libreSancion++;

            if (adj.fileType.indexOf('Detalle') >= 0)
                detalleTotales++;

            if (adj.fileType.indexOf('enfermedades') >= 0)
                declaracionJuradaEnfermedadesTotales++;

            if (adj.fileType.indexOf('Declaracion Jurada') >= 0)
                DDJJTotales++;
        });
        let titulosRepetidos = 0;
        let cantidadNumerosTitulo = 0;
        if (localStorage.userType !== "0") {
            titulosRepetidos = datos.adjuntos.filter(a => a.fileType.replace(/\d/g, "").trim() === "Título - " + tituloAnterior.replace(/\d/g, "").trim()).length;

            if(tituloAnterior.match(/\d+/g) !== null)
            cantidadNumerosTitulo = tituloAnterior.match(/\d+/g).length;
        }

        //si el titulo anterior tiene mas de un numero reemplazo todos menos el ultimo, si tiene un numero solo reemplazo el primero y si no tiene numeros reemplazo solo el nombre

        for (let i = 0; i < datos.adjuntos.length;) {
            datos.adjuntos.map((adj, index) => {

                if (
                    (adjuntosOrdenados >= 0 && adjuntosOrdenados < dniTotales && adj.fileType.indexOf('DNI') >= 0) ||
                    (adjuntosOrdenados >= dniTotales && adjuntosOrdenados < (dniTotales + fotoTotales) && adj.fileType.indexOf('Foto') >= 0) ||
                    (adjuntosOrdenados >= (dniTotales + fotoTotales) && adjuntosOrdenados < (dniTotales + fotoTotales + resolucionTotales) && adj.fileType.indexOf('Ministerio') >= 0) ||
                    (adjuntosOrdenados >= (dniTotales + fotoTotales + resolucionTotales) && adjuntosOrdenados < (dniTotales + fotoTotales + resolucionTotales + titulosTotales) && adj.fileType.search('-') >= 0) ||
                    (adjuntosOrdenados >= (dniTotales + fotoTotales + resolucionTotales + titulosTotales) && adjuntosOrdenados < (titulosTotales + fotoTotales + dniTotales + resolucionTotales + planTotales) && adj.fileType.indexOf('Plan') >= 0) ||
                    (adjuntosOrdenados >= (dniTotales + fotoTotales + resolucionTotales + titulosTotales + planTotales) && adjuntosOrdenados < (titulosTotales + fotoTotales + dniTotales + resolucionTotales + planTotales + libreSancion) && adj.fileType.indexOf('sanción') >= 0) ||
                    (adjuntosOrdenados >= (dniTotales + fotoTotales + resolucionTotales + titulosTotales + planTotales + libreSancion) && adjuntosOrdenados < (titulosTotales + fotoTotales + dniTotales + resolucionTotales + planTotales + libreSancion + detalleTotales) && adj.fileType.indexOf('Detalle') >= 0) ||
                    (adjuntosOrdenados >= (dniTotales + fotoTotales + resolucionTotales + titulosTotales + planTotales + libreSancion + declaracionJuradaEnfermedadesTotales) && adjuntosOrdenados < (titulosTotales + fotoTotales + dniTotales + resolucionTotales + planTotales + libreSancion + declaracionJuradaEnfermedadesTotales + detalleTotales) && adj.fileType.indexOf('enfermedades') >= 0) ||
                    (adjuntosOrdenados >= (dniTotales + fotoTotales + resolucionTotales + titulosTotales + planTotales + libreSancion + declaracionJuradaEnfermedadesTotales + detalleTotales) && adjuntosOrdenados < (titulosTotales + fotoTotales + dniTotales + resolucionTotales + planTotales + libreSancion + declaracionJuradaEnfermedadesTotales + detalleTotales + analiticoTotales) && adj.fileType.indexOf('Analítico') >= 0) ||
                    (adjuntosOrdenados >= (dniTotales + fotoTotales + resolucionTotales + titulosTotales + planTotales + libreSancion + declaracionJuradaEnfermedadesTotales + detalleTotales + analiticoTotales) && adjuntosOrdenados < (titulosTotales + fotoTotales + dniTotales + resolucionTotales + planTotales + libreSancion + declaracionJuradaEnfermedadesTotales + detalleTotales + analiticoTotales + DDJJTotales) && adj.fileType === "Declaracion Jurada") ||
                    (adjuntosOrdenados >= (dniTotales + fotoTotales + resolucionTotales + titulosTotales + planTotales + libreSancion + declaracionJuradaEnfermedadesTotales + detalleTotales + analiticoTotales + DDJJTotales) && adjuntosOrdenados < (titulosTotales + fotoTotales + dniTotales + resolucionTotales + planTotales + declaracionJuradaEnfermedadesTotales + libreSancion + detalleTotales + analiticoTotales + DDJJTotales + 1) && adj.fileType === "SOLICITUD INCORPORACIÓN DE TÍTULO AL LIBRO ESPECIAL DE TÍTULOS HOMOLOGADOS")) {

                    if (localStorage.userType !== "0") {
                        //VALIDO SI EL TITULO QUE VOY A MODIFICAR TIENE UN NUMERO EN EL NOMBRE
                        if (cantidadNumerosTitulo > 0 && adj.fileType.replace(/[0-9]/g, '').trim() === "Título - " + tituloAnterior.replace(/[0-9]/g, '').trim()) {

                            //VALIDO LA CANTIDAD DE NUMEROS QUE TIENE EL ADJUNTO QUE VOY A REEMPLAZAR
                            let cantidadNumerosFor = adj.fileType.match(/\d+/g).length;
                            if (cantidadNumerosFor === cantidadNumerosTitulo) {
                                adj = { fileType: "Título - " + tituloNuevo, fileName: adj.fileName, extension: adj.extension, file: adj.file, size: adj.size, url: adj.url, saved: true }
                            } else {
                                const ultimoNumero = adj.fileType.match(/(\d+)[^\d]*$/)[1];
                                adj = { fileType: "Título - " + tituloNuevo + " " + ultimoNumero, fileName: adj.fileName, extension: adj.extension, file: adj.file, size: adj.size, url: adj.url, saved: true }
                            }

                        } else if (adj.fileType.search('-') > 0) {

                            if (adj.fileType.replace(/[0-9]/g, '').trim() === "Título - " + tituloAnterior.replace(/[0-9]/g, '').trim()) {

                                let cantidadNumerosFor = adj.fileType.match(/\d+/g);
                                if (cantidadNumerosFor !== null)
                                    tituloNuevo = tituloNuevo + " " + cantidadNumerosFor.length;

                                adj = { fileType: "Título - " + tituloNuevo, fileName: adj.fileName, extension: adj.extension, file: adj.file, size: adj.size, url: adj.url, saved: true }
                            }
                        }
                    }



                    adjuntosArray.push(adj);
                    adjuntosOrdenados++;
                    i++
                }
            });

        }
        setDatos({
            ...datos,
            adjuntos: adjuntosArray
        })

    }

    const guardarContinuar = () => {

        if (titulosAgregados.length > 2) {
            Swal.fire({
                title: 'Atención',
                html: 'Solo puede ingresar hasta <b> dos titulos</b>',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            })
            return false;
        }

        setDatos({
            ...datos,
            titulos: titulosAgregados
        })
        setLoaderBtnGuardar(true)
        setBloquearBtn(true);
        guardarDatosMatriculas({ datos: JSON.stringify(datos), estado: idEstado, tpo_tramite: 9 }).then((value) => {

            setMatriculas(value.data);        
                setLoaderBtnGuardar(false)
                setBloquearBtn(false);
                
    
                if (value.data.resultado) {
    
                    if (localStorage.userType !== "10") {
                        setValue("5");
                    }
                    else {
                        localStorage.setItem('idLegajoTemporal', value.data.result.idLegajoTemporal);
                        setValue("3");
                    }
    
                } else {
                    Swal.fire({
                        title: 'Atención',
                        html: value.data.mensaje !== '' && value.data.mensaje !== null ? value.data.mensaje : 'Ocurrio un error al guardar los datos. Por favor vuelva a intentarlo',
                        icon: 'warning',
                        showCloseButton: true,
                        showCancelButton: false,
                        confirmButtonText:
                            'Aceptar',
                        cancelButtonText:
                            'Cancelar',
                        allowOutsideClick: true
                    })
                    return false;
                }
        });
    }

    useEffect(() => {
        if(localStorage.userType === "1" && titulosAgregados && titulosAgregados.length > 0){
            for (const titulo of titulosAgregados) {
                if(titulo.codTitulo && Titulos && Titulos.length > 0){
                    titulo.nombreTituloNuevo = Titulos.find(t => t.codTitulo === titulo.codTitulo).tituloNombre;
                }

                if(titulo.codUniversidad && Universidades && Universidades.length > 0){
                    titulo.nombreUniversidadNuevo = Universidades.find(u => u.codUniversidad === titulo.codUniversidad).nombre;
                }
            }
        }
    }, [titulosAgregados, Titulos, Universidades]);

    const convertDateFormat = string => {

        var info = string.split('-');

        if (info[2] + '/' + info[1] + '/' + info[0] === "01/01/0001") {
            return "";
        }

        return info[2] + '/' + info[1] + '/' + info[0];

    }

    const [openAutocompleteUniversidad, setOpenAutocompleteUniversidad] = useState(false);
    const [openAutocompleteTitulos, setOpenAutocompleteTitulos] = useState(false);
    const { handleSubmit, formState: { errors } } = useForm();

    return (
        <Fragment>
            <Typography sx={{ mb: 1 }} >* {(idEstado === ESTADOS_TRAMITE.VALIDADO || idEstado === ESTADOS_TRAMITE.PRE_CARGA_FINALIZADA || idEstado === ESTADOS_TRAMITE.PRE_CARGA_VALIDADA) ? <b>Título por ud. registrado</b> : <b>Para cargar su título deberá hacer clic en el botón
                “+”.</b>}</Typography>
            <Grid container spacing={2} >

                {titulosAgregados.map((tituloAgregado, index) =>
                    <Grid item md={6} key={index}>
                        <Card variant='custom' sx={{ boxShadow: 5 }}>
                            <CardContent>
                                <Grid container>
                                    <Grid item md={12} className={clases.centrarContenido}>
                                        <h3 style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexWrap: 'wrap',
                                        }}><SchoolIcon />  {tituloAgregado.nombreTituloNuevo ? tituloAgregado.nombreTituloNuevo: tituloAgregado.nombreTitulo}</h3>
                                    </Grid>

                                    <Grid item md={12} sx={{ mb: 2 }}>
                                        <b>Universidad: {tituloAgregado.nombreUniversidadNuevo? tituloAgregado.nombreUniversidadNuevo:tituloAgregado.nombreUniversidad}</b>
                                    </Grid>

                                    <Grid item md={12} sx={{ mb: 2 }}>
                                        <b>Promedio: {tituloAgregado.promedio}</b>
                                    </Grid>

                                    <Grid item md={12} sx={{ mb: 2 }}>
                                        <b>Fec. Última Materia : {tituloAgregado.fechaUltimaMateria !== null && convertDateFormat(tituloAgregado.fechaUltimaMateria)}</b>
                                    </Grid>

                                    <Grid item md={12} sx={{ mb: 2 }}>
                                        <b>Fec. Emisión de Título: {tituloAgregado.fechaEmisionTitulo !== null && convertDateFormat(tituloAgregado.fechaEmisionTitulo)}</b>
                                    </Grid>
                                    <Grid item md={12} sx={{ mb: 2 }}>
                                        <b>Resolución Ministerio de Educación: {tituloAgregado.resolucion + '/' + tituloAgregado.anioResolucion}</b>
                                    </Grid>

                                    {(localStorage.userType !== "1" && bloquearBtn !== true && (idEstado === 1 || idEstado === 8)) &&
                                        <Grid item md={12} className={clases.floatRight}>
                                            <Fab className={clases.deleteFab} sx={{ mr: 2, color: "white" }} aria-label="edit" onClick={() => eliminarTitulo(tituloAgregado.id)}>
                                                <DeleteIcon />
                                            </Fab>
                                            <Fab color="secondary" aria-label="edit" onClick={() => handleOpen(index)}>
                                                <EditIcon />
                                            </Fab>
                                        </Grid>
                                    }
                                    {(localStorage.userType !== "0" && (idEstado === 3 || idEstado === 8)) &&
                                        <Grid item md={12} className={clases.floatRight}>
                                            <Fab color="secondary" aria-label="edit" onClick={() => handleOpen(index)}>
                                                <EditIcon />
                                            </Fab>
                                        </Grid>
                                    }

                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
                {(localStorage.userType !== "1" && (idEstado == 1 || idEstado === 3 || idEstado === 8) && bloquearBtn !== true && titulosAgregados.length < 1) &&
                    <Grid item md={12} className={clases.floatRight}>
                        <Fab color="secondary" aria-label="edit" onClick={handleOpen}>
                            <AddIcon />
                        </Fab>
                    </Grid>
                }
            </Grid>

            <Modal
                open={open}
                className={clases.modales}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Card className={clases.modal}>
                    <CardContent>
                        <Grid container style={{ marginBottom: 15 }}>

                            <Grid item container justifyContent="flex-end" >
                                <Typography sx={{}} ></Typography>

                                <CloseIcon style={{ cursor: "pointer" }} onClick={() => setOpen(false)} />
                            </Grid>
                        </Grid>
                        <form onSubmit={handleSubmit(agregarTituloArray)}>
                            <Grid container spacing={2}>
                                <Grid item md={8} xs={12}>
                                    <TextField label="Título"
                                        name='nombreTitulo'
                                        onChange={onTitleNameChange}
                                        //onKeyPress={avoidNumbers}
                                        required
                                        value={datosTitulo.nombreTitulo}
                                        variant={localStorage.userType !== "0" ? "filled" : "outlined"}
                                        fullWidth
                                        disabled={localStorage.userType !== "0" ? true : false}
                                        type="text"
                                    />

                                    {/* <Typography sx={{ mb: 1, ml: 1, fontSize: anchoPantalla < 700 ? 12 : 16 }} ><b>Si la denominación de su título no coincide con los detallados, contáctese con el Sector Matrículas  al 0221 – 441 4100. </b></Typography> */}
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <TextField 
                                        label="Promedio"
                                        name='promedio'
                                        variant={localStorage.userType !== "0" ? "filled" : "outlined"}
                                        value={datosTitulo.promedio}
                                        onChange={inputHandleChange}
                                        required
                                        disabled={localStorage.userType !== "0" ? true : false}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    {localStorage.userType !== "0"
                                        &&
                                        <Autocomplete
                                            open={openAutocompleteTitulos}
                                            name='tituloGuardar'
                                            onClose={() => setOpenAutocompleteTitulos(false)}
                                            onChange={handleTitulo}
                                            required
                                            options={titulosAgregarAutocomplete}
                                            noOptionsText={'No se encontraron Títulos'}
                                            defaultValue={titulosSelect}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) => <TextField {...params} onClick={() => setOpenAutocompleteTitulos(true)} fullWidth required label="Título a inscribir" />}
                                        />
                                    }
                                    {/* <Typography sx={{ mb: 1, ml: 1, fontSize: anchoPantalla < 700 ? 12 : 16 }} ><b>Si la denominación de su título no coincide con los detallados, contáctese con el Sector Matrículas  al 0221 – 441 4100. </b></Typography> */}
                                </Grid>

                                <Grid item md={12} xs={12}>

                                    <TextField label="Universidad"
                                        name='nombreUniversidad'
                                        onChange={inputHandleChange}
                                        required
                                        value={datosTitulo.nombreUniversidad}
                                        variant={localStorage.userType !== "0" ? "filled" : "outlined"}
                                        disabled={localStorage.userType !== "0" ? true : false}
                                        fullWidth
                                        type="text"
                                    />

                                    {/* <Typography sx={{ mb: 1, ml: 1, fontSize: anchoPantalla < 700 ? 12 : 16 }} ><b>Si no se encuentra la universidad, contáctese con el Sector Matrículas al 0221 – 441 4100.</b></Typography> */}

                                </Grid>

                                {localStorage.userType !== "0"
                                    &&
                                    <Grid item md={12} xs={12}>
                                        <Autocomplete
                                            open={openAutocompleteUniversidad}
                                            onClose={() => setOpenAutocompleteUniversidad(false)}
                                            onChange={ingresaUniversidad}
                                            required
                                            options={universidadesObjeto}
                                            noOptionsText={'No se encontraron Universidades'}
                                            getOptionLabel={(option) => option.label}
                                            value={universidadSeleccionada}
                                            renderInput={(params) => <TextField {...params} onClick={() => setOpenAutocompleteUniversidad(true)} fullWidth required label="Universidad a inscribir" />}
                                        />

                                    </Grid>
                                }

                                <Grid item md={4} xs={12}>
                                    <TextField label="Fec. Emisión de Título"
                                        name='fechaEmisionTitulo'
                                        onChange={inputHandleChange}
                                        required
                                        value={datosTitulo.fechaEmisionTitulo}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        variant={localStorage.userType !== "0" ? "filled" : "outlined"}
                                        disabled={localStorage.userType !== "0" ? true : false}
                                        fullWidth
                                        type="date"
                                    />
                                </Grid>

                                <Grid item md={4} xs={12}>
                                    <TextField label="Fec. Última Materia"
                                        name='fechaUltimaMateria'
                                        onChange={inputHandleChange}
                                        required
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        value={datosTitulo.fechaUltimaMateria}
                                        variant={localStorage.userType !== "0" ? "filled" : "outlined"}
                                        disabled={localStorage.userType !== "0" ? true : false}
                                        fullWidth
                                        type="date"
                                    />
                                </Grid>

                                <Grid item md={4} xs={12}>
                                    <Grid container >
                                        <Grid item md={8} xs={8}>

                                            <TextField
                                                name='resolucion'
                                                label='Resolución del Ministerio de Educación de la Nación que otorga reconocimiento oficial'
                                                onChange={inputHandleChange}
                                                required
                                                inputProps={{ maxLength: 10 }}
                                                InputLabelProps={{
                                                    style: { fontSize: localStorage.userType !== "0" ? 15 : 12 } // Cambiar el tamaño del texto a 15px
                                                }}
                                                value={datosTitulo.resolucion}
                                                variant={localStorage.userType !== "0" ? "filled" : "standard"}
                                                disabled={localStorage.userType !== "0" ? true : false}
                                                fullWidth
                                                type="text"
                                            />
                                        </Grid>
                                        <Grid sx={{ mt: 3, mx: 1 }} >/</Grid>
                                        <Grid item md={3} xs={3}>
                                            <TextField label="Año"
                                                name='anioResolucion'
                                                onChange={inputHandleChange}
                                                inputProps={{ maxLength: 4 }}
                                                required
                                                value={datosTitulo.anioResolucion}
                                                variant={localStorage.userType !== "0" ? "filled" : "standard"}
                                                disabled={localStorage.userType !== "0" ? true : false}
                                                fullWidth
                                                type="text"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12}>
                                    <Typography className={clases.color_rojo} style={errorFechas} >{errorFechasTexto}</Typography>
                                </Grid>
                                <Grid item md={12} className={clases.floatRight}>
                                    <Button variant="contained" color="info" disabled={btnGuardarTituloEstado} type="submit" startIcon={<CheckIcon />}>
                                        Confirmar
                                    </Button>
                                </Grid>

                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Modal>
            <Grid container>
                <Grid item md={12}> </Grid>
                <Grid item md={6} xs={6} sx={{ mt: 3 }} container>

                    <Button startIcon={<ArrowBackIosIcon />} onClick={() => setValue(bloquearTabs === 2 || bloquearTabs === 4 || bloquearTabs === 6 || bloquearTabs === 7 ? "1" : "3")} variant="contained" type="submit" color="error" >
                        Atras
                    </Button>

                </Grid>
                <Grid item md={6} xs={6}>
                    <Grid sx={{ mt: 3 }} container justifyContent="flex-end">
                        <Button disabled={bloquearBtn} style={mostrarGuardar} endIcon={<ArrowForwardIosIcon />} onClick={() => guardarContinuar()} variant="contained" type="submit" color="secondary" >

                            {loaderBtnGuardar === true ? <CircularProgress /> : ''} Continuar
                        </Button>
                        {/* <Button endIcon={<ArrowForwardIosIcon />} onClick={() => setValue("4")} variant="contained" type="submit" color="info" >
                            Continuar
                        </Button> */}
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    );
}
