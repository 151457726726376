import { useState, useEffect } from "react";
import { devolverNombreEstadoTramite, formatDate } from "utils/utils";

export const usePaginate = (list, itemsPerPage = 10, setListaTramites, tramitesOriginales) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [cleanList, setCleanList] = useState([]);
    const [currentList, setCurrentList] = useState([]);

    useEffect(() => {
        const array = [...list];
        const sortedList = array.sort((a, b) => b.$id - a.$id);
        const sortedListByidEstado = sortedList.sort((a, b) => {
            if (a.idEstado === 2 && b.idEstado !== 2) return -1;
            if (a.idEstado !== 2 && b.idEstado === 2) return 1;
            if (a.idEstado === 6 && b.idEstado !== 6) return -1;
            if (a.idEstado !== 6 && b.idEstado === 6) return 1;
            if (a.idEstado === 1 && b.idEstado !== 1) return -1;
            if (a.idEstado !== 1 && b.idEstado === 1) return 1;
            return 0;
        });
        const startIndex = currentPage * itemsPerPage;
        setCurrentList(sortedListByidEstado.slice(startIndex, startIndex + itemsPerPage));
        setCleanList(sortedListByidEstado);
    }, [list, currentPage, itemsPerPage]);

    const setPage = (goToPage) => {
        setCurrentPage(goToPage);
    };

    const nextPage = () => {
        if ((currentPage + 1) * itemsPerPage < list.length) {
            setCurrentPage(currentPage + 1);
        }
    };
    const prevPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const searchList = (value) => {
        const array = [...list];
        const sortedList = array.sort((a, b) => b.$id - a.$id);
        const filteredList = sortedList.filter(item => {
            let jsonDatos = JSON.parse(item.jsonDatos);
            if (jsonDatos.bajaFallecimiento !== null) {
                jsonDatos.bajaFallecimiento[0].fechaInscripcion = item.fehaInscripcion ? formatDate(item.fehaInscripcion) : "-" ;
                jsonDatos.bajaFallecimiento[0].estado = devolverNombreEstadoTramite(item.idEstado, jsonDatos.Observado);
                return Object.values(jsonDatos.bajaFallecimiento[0]).some(val => val !== null && val.toString().trim().toLowerCase().includes(value.trim().toLowerCase()));
            }
            return false;
        });
        setListaTramites(filteredList);
        setCurrentList(filteredList.slice(0, itemsPerPage));
        setCurrentPage(0);
    };

    const clearList = () => {
        const startIndex = currentPage * itemsPerPage;
        setListaTramites(tramitesOriginales);
        setCleanList(tramitesOriginales)
        setCurrentList(cleanList.slice(startIndex, startIndex + itemsPerPage));
    };

    return { 
        currentList, 
        currentPage,
        nextPage, 
        prevPage,
        searchList,
        clearList,
        setPage
    };
};