import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, Modal, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Fade from '@mui/material/Fade';

function FilePreview({ file, onClose, opened }) {
    const useStyles = makeStyles( _ => ({
        modalWindow: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: 'rgb(255, 255, 255)',
            border: '2px solid #000',
            padding: '15px',
        }
    }));

    const tipoTramite =  Number(localStorage.getItem('tpoTramite'));

    const clases = useStyles();
    return (
        <Modal keepMounted open={opened} onClose={onClose}>
            <Fade in={opened}>
                <Box className={clases.modalWindow}>
                    <Grid item container justifyContent="flex-end" >
                        <CloseIcon style={{ cursor: "pointer" }} onClick={onClose} />

                    </Grid>
                    <Typography sx={{marginBottom: "2vh"}} id="modal-modal-title" variant="h6" component="h2">
                        {file.fileName}
                    </Typography>
                    {(file.extension === 'image/png' || file.extension === 'image/jpeg') && <ImagePreview src={tipoTramite === 9 ? file.file : file.url} />}
                    {file.extension === 'application/pdf' && <PDFPreview src={file.file} />}
                </Box>
            </Fade>
        </Modal>
    );
}


function PDFPreview({ src }) {

    const [anchoPantalla, setAnchoPantalla] = useState(0);
    const [pdf, setPdf] = useState(null);

    useEffect(() => {
        async function createPDFFile(){
            const file = await fetch(src).then(f => f.blob());
            setPdf(URL.createObjectURL(file));
        }
        createPDFFile();
    }, [src]);

    useEffect(() => {
        setAnchoPantalla(window.screen.width);

        function handleResize() {
            if ((window.innerWidth >= 900 && anchoPantalla < 900) ||
                (window.innerWidth <= 900 && anchoPantalla > 900) ||
                anchoPantalla === 0) {
                setAnchoPantalla(window.innerWidth);
            }
        }
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    
    return (<iframe
        title='PDF'
        src={pdf}
        width={anchoPantalla < 700 ? "300" : "1000"}
        style={{height:"80vh", maxWidth:"80vw"}}
        // height="400"
        type="application/pdf" />);
}

function ImagePreview({ src }) {
    return (<img src={src} alt='Subir archivo' style={{height:"60vh", maxWidth:"80vw"}} />);
}

export default FilePreview;