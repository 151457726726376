import { useState } from 'react';
import { Button, Modal, Paper, Typography, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link, Box, FormControlLabel, Radio } from "@mui/material";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import { ModalAceptarSeleccion } from './ModalAceptarSeleccion';
import { obtenerProfConTitulos, verificarSiTieneTramiteFallecido } from 'app/features/BajaPorFallecimiento/bajaFallecimientoApi';
import { formatDNI } from 'utils/utils';

export const ModalProfesionales = ({ datos, setDatos, profBuscados, setShowModal, showModal, tpoUser }) => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedProf, setSelectedProf] = useState(null);
    const [selectedRadio, setSelectedRadio] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);

    const seleccionarProf = (nombre, apellido, legProf, sexo, resp) => {
        const newData = { ...datos };
        newData.bajaFallecimiento[0].Nombre = nombre;
        newData.bajaFallecimiento[0].Apellido = apellido;
        newData.bajaFallecimiento[0].LegProf = legProf;
        // newData.bajaFallecimiento[0].Tomo = tomo;
        // newData.bajaFallecimiento[0].Folio = folio;
        newData.bajaFallecimiento[0].Sexo = sexo;
        // newData.bajaFallecimiento[0].Titulo = titulo.trim();
        newData.bajaFallecimiento[0].TitulosFallecido = resp
        setDatos(newData);
        setShowConfirmModal(false);
        setShowModal(false);
    }

    const handleClose = () => {
        setShowModal(false);
    }

    const handleConfirmClose = () => {
        setShowConfirmModal(false);
        
    }

    const handleConfirmProf = async() => {
        if (formatDNI(selectedProf.nroDocumento) === datos.NroDocumento) {
            setSelectedProf(null);
            setSelectedRadio(null);
            setErrorMsg("No puede darse de baja a si mismo")
            setShowConfirmModal(true);
            return;
        }
        const resp = await verificarSiTieneTramiteFallecido(selectedProf.legProf)

        if (resp.resultado === true) {
            setShowConfirmModal(true);
        } else {
            setSelectedProf(null);
            setSelectedRadio(null);
            setErrorMsg(resp.message)
            setShowConfirmModal(true);
        }
    }

    const handleConfirmAccept = async() => {
        if (selectedProf) {
            const { $values } = await obtenerProfConTitulos(selectedProf.legProf);
            seleccionarProf(selectedProf.nombre, selectedProf.apellido, selectedProf.legProf, selectedProf.sexo, $values);
            setShowModal(false);
            
        }
    }

    const handleRadioChange = (prof) => {
        setSelectedProf(prof);
        setSelectedRadio(prof.legProf);
    }

    return (
        <>
            <Modal open={showModal} onClose={handleClose} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Paper style={{ position: 'relative', padding: '16px', width: '80%', maxWidth: '800px' }}>
                    <IconButton style={{ position: 'absolute', top: '8px', right: '8px' }} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                    {
                    errorMsg  ? <Typography variant="body1" style={{ marginBottom: '10px' }} mt={2}><b>{errorMsg.trim()}.</b></Typography>
                    :    
                    <Box>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>Apellido</b></TableCell>
                                        <TableCell><b>Nombre</b></TableCell>
                                        <TableCell><b>Documento</b></TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}><b>Seleccionar</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {profBuscados.map((prof) => (
                                        <TableRow key={prof.legProf}>
                                            <TableCell><b>{prof.apellido}</b></TableCell>
                                            <TableCell><b>{prof.nombre}</b></TableCell>
                                            <TableCell><b>{formatDNI(prof.nroDocumento)}</b></TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <FormControlLabel control={<Radio checked={selectedRadio === prof.legProf} onChange={() => handleRadioChange(prof)}  />} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box>
                            <Typography sx={{ mt: 2 }} >* Si los datos son incorrectos contáctese con el Sector Matrículas al 0221-4414100. </Typography>
                        </Box>
                        <Box display='flex' justifyContent='flex-end'>
                            <Button variant='contained' color='primary' onClick={handleConfirmProf}>
                                Confirmar
                            </Button>
                        </Box>
                    </Box>
                    }
                </Paper>
            </Modal>
            {(selectedProf !== null) && 
               <ModalAceptarSeleccion datos={datos} setDatos={setDatos} showConfirmModal={showConfirmModal} selectedProf={selectedProf} handleConfirmClose={handleConfirmClose} handleConfirmAccept={handleConfirmAccept} tpoUser={tpoUser}/>
            }
            
        </>
    )
}