import axios from 'axios';

import {
	HEADERS_TOKEN,
	LEGAJO_INSCRIPTO,
	VALIDAR_CUIT,
	VALIDAR_EMAIL,
	CAMBIAR_ESTADO,
	VALIDAR_EMAIL_ACTUALIZACION,
	GENERAR_OPERACION,
	PAGO_TASA,
	DERECHO_INSCRIPCION,
	MONTO_TASA,
	OBTENER_NUMERO_OPERACION,
	OBTENER_ESTADO_OPERACION,
	OBTENER_OPERACION,
	GRABAR_OPERACION,
	GENERAR_OPERACION_ALTA_TITULO,
	GENERAR_QR,
	ELIMINAR_QR,
	OBTENER_QR,
	OBTENER_ESTADO_OPERACION_QR,
	VERIFICAR_PAGO
} from 'constants/routes/endpoints';



export const getProfesionalLegajo = ({ idLegajo, token }) => {

	//validacion para cuando consultamos los datos desde el login del editar datos
	if (idLegajo === null || idLegajo === undefined)
		idLegajo = localStorage.idLegajo

	let tokenNuevo = HEADERS_TOKEN;

	if (token !== null && token !== undefined) {
		tokenNuevo = {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token
		}
	}

	return new Promise((resolve, reject) => {
		return axios({
			url: LEGAJO_INSCRIPTO + "/" + idLegajo,
			method: 'GET',
			headers: tokenNuevo,
		})
			.then(response => response)
			.then(json => {
				return resolve({ data: json.data });
			})
			.catch(err => {

				if (err.response.status === 401 || err.response.status === 500) {
					window.location.href = `/login`;
				}
			});
	});
};

export const getProfesionalValidarCuit = (cuit) => {
	// console.log(cuit)
	return new Promise((resolve, reject) => {
		return axios({
			url: VALIDAR_CUIT + "/" + cuit,
			method: 'GET',
			headers: HEADERS_TOKEN,
		})
			.then(response => response)
			.then(json => {
				return resolve({ data: json.data });
			})
			.catch(err => {
				var errorJson = err.toJSON();

				console.log(errorJson.config.url);
				console.log(errorJson.message);
				
				return resolve({ data: errorJson });
			});
	});
};

export const getProfesionalValidarMail = (email) => {

	return new Promise((resolve, reject) => {
		return axios({
			url: VALIDAR_EMAIL + "/" + email,
			method: 'GET',
			headers: HEADERS_TOKEN,
		})
			.then(response => response)
			.then(json => {
				return resolve({ data: json.data });
			})
			.catch(err => {
				if (err.response.status === 401 || err.response.status === 500) {
					window.location.href = `/login`;
				}
			});
	});
};
export const getProfesionalValidarMailActualizacion = (email, leg_prof) => {
	// console.log(email, leg_prof)
	// console.log(VALIDAR_EMAIL_ACTUALIZACION + "/" + email + "/" + leg_prof);
	return new Promise((resolve, reject) => {
		return axios({
			url: VALIDAR_EMAIL_ACTUALIZACION + "/" + email + "/" + leg_prof,
			method: 'GET',
			headers: HEADERS_TOKEN,
		})
			.then(response => response)
			.then(json => {
				return resolve({ data: json.data });
			})
			.catch(err => {
				console.log(err.response);
				return false;
				if (err.response.status === 401 || err.response.status === 500) {
					window.location.href = `/login`;
				}
			});
	});
};

export const postCambioEstadoTramite = () => {

	return new Promise((resolve, reject) => {
		return axios({
			url: CAMBIAR_ESTADO + "/" + localStorage.idLegajoTemporal,
			method: 'POST',
			headers: HEADERS_TOKEN
		})
			.then(response => response)
			.then(json => {
				return resolve({ data: json.data });
			})
			.catch(err => {

				if (err.response.status === 401 || err.response.status === 500) {
					window.location.href = `/login`;
				}
			});
	});
};



export const generarOperacion = () => {

	return new Promise((resolve, reject) => {
		return axios({
			url: GENERAR_OPERACION + "/" + localStorage.idLegajoTemporal,
			method: 'POST',
			headers: HEADERS_TOKEN
		})
			.then(response => response)
			.then(json => {
				return resolve({ data: JSON.parse(json.data) });
			})
			.catch(err => {

				return false;
				// if (err.response.status === 401 || err.response.status === 500) {
				// 	window.location.href = `/login`;
				// }
			});
	});
};


export const pagoMatricula = async (formData) => {
	
	return new Promise((resolve, reject) => {
		return axios({
			url: PAGO_TASA,
			method: 'POST',
			headers: HEADERS_TOKEN,
			data: formData
		})
			.then(response => response)
			.then(json => {
				
				return resolve({ data: JSON.parse(json.data) });
			})
			.catch(err => {

				return false;
				// if (err.response.status === 401 || err.response.status === 500) {
				// 	window.location.href = `/login`;
				// }
			});
	});
}



export const getResultadoPagoDeTasa =  async() =>{
	const idLegajoTemporal = localStorage.idLegajoTemporal;

	try {
		
		let resp = await axios.get(`${PAGO_TASA}/${idLegajoTemporal}`, { headers: HEADERS_TOKEN });
		resp = JSON.parse(resp.data);
		return resp;
	} catch (error) {
		console.log(error);
		return false;
	}
	
}


export const getDerechoInscripcion =  async() =>{
	const idLegajoTemporal = localStorage.idLegajoTemporal;

	try {

		let resp = await axios.get(`${DERECHO_INSCRIPCION}/${idLegajoTemporal}`, { headers: HEADERS_TOKEN });
		resp = JSON.parse(resp.data);
		return resp;
	} catch (error) {
		console.log(error);
		return false;
	}
	
}

export const getMontoDeTasa = async() => {
	
	try {
		let resp = await axios.get(`${MONTO_TASA}`, { headers: HEADERS_TOKEN });
		resp = JSON.parse(resp.data);
		return resp;
	} catch (error) {
		console.log(error)
		return false;
	}


}

export const getNroOperacion = async() => {
	const idLegajoTemporal = localStorage.idLegajoTemporal
	try {
		let resp = await axios.get(`${OBTENER_NUMERO_OPERACION}/${idLegajoTemporal}`, {headers: HEADERS_TOKEN});
		resp = JSON.parse(resp.data);
		return resp;
	} catch (error) {
		console.log(error)
		return false;
	}
}

export const getEstadoOperacion = async() => {
	const idLegajoTemporal = localStorage.idLegajoTemporal
	try {
		let resp = await axios.get(`${OBTENER_ESTADO_OPERACION}/${idLegajoTemporal}`, {headers: HEADERS_TOKEN});
		resp = JSON.parse(resp.data);
		return resp;
	} catch (error) {
		console.log(error)
		return false;
	}
}

export const getOperacion = async() => {
	const idLegajoTemporal = localStorage.idLegajoTemporal
	try {
		let resp = await axios.get(`${OBTENER_OPERACION}/${idLegajoTemporal}`, {headers: HEADERS_TOKEN});
		
		resp = JSON.parse(resp.data);
		return resp;
	} catch (error) {
		console.log(error)
		return false;
	}
}


export const grabarProfIntermediaTasa = async() => {
	const idLegajoTemporal = localStorage.idLegajoTemporal
	// console.log(idLegajoTemporal)
	try {
		let resp = await axios.post(`${GRABAR_OPERACION}/${idLegajoTemporal}`, {headers: HEADERS_TOKEN});
		
		resp = JSON.parse(resp.data);
		return resp;
	} catch (error) {
		console.log(error)
		return false;
	}
}

export const grabarOperacionAltaTitulo = async() => {
	const idLegajoTemporal = localStorage.idLegajoTemporal
	const legprof = localStorage.idLegajo
	// console.log(idLegajoTemporal)
	try {
		let resp = await axios.post(`${GENERAR_OPERACION_ALTA_TITULO}/${idLegajoTemporal}/${legprof}`, {headers: HEADERS_TOKEN});
		
		resp = JSON.parse(resp.data);
		return resp;
	} catch (error) {
		console.log(error)
		return false;
	}
}

export const generarCodigoQR = async(idOperacion) => {
	const idLegajoTemporal = localStorage.idLegajoTemporal
	try {
		let resp = await axios.post(`${GENERAR_QR}/${idOperacion}/${idLegajoTemporal}`, {headers: HEADERS_TOKEN});
		// console.log(resp)
		// resp = JSON.parse(resp.data);
		return resp.data;
	} catch (error) {
		console.log(error)
		return false;
	}
}

export const eliminarCodigoQR = async(idOperacion) => {
	const idLegajoTemporal = localStorage.idLegajoTemporal
	try {
		let resp = await axios.post(`${ELIMINAR_QR}/${idOperacion}/${idLegajoTemporal}`, {headers: HEADERS_TOKEN});
		// console.log(resp)
		// resp = JSON.parse(resp.data);
		return resp.data;
	} catch (error) {
		console.log(error)
		return false;
	}
}

export const obtenerCodigoQR = async() => {
	const idLegajoTemporal = localStorage.idLegajoTemporal
	try {
		let resp = await axios.get(`${OBTENER_QR}/${idLegajoTemporal}`, {headers: HEADERS_TOKEN});
		// console.log(resp)
		resp = JSON.parse(resp.data);
		return resp;
	} catch (error) {
		console.log(error)
		return false;
	}
}

export const obtenerEstadoOperacionQr = async(idOperacion) => {
	const idLegajoTemporal = localStorage.idLegajoTemporal
	try {
		let resp = await axios.get(`${OBTENER_ESTADO_OPERACION_QR}/${idLegajoTemporal}/${idOperacion}`, {headers: HEADERS_TOKEN});
		resp = JSON.parse(resp.data);
		return resp;
	} catch (error) {
		console.log(error)
		return false;
	}
}


export const obtenerVerificacionPagoQr = async(idOperacion) => {
	try {
		let resp = await axios.get(`${VERIFICAR_PAGO}/${idOperacion}`, {headers: HEADERS_TOKEN});
		resp = JSON.parse(resp.data);
		return resp;
	} catch (error) {
		console.log(error)
		return false;
	}
}

