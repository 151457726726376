import React, { useState, useEffect, Fragment } from 'react';
import { Chat } from 'components/chat/chat';
// import { ArchivosAdjuntos } from 'components/matriculas/archivosAdjuntos';
// import DatosPersonales from 'components/bajaFallecimiento/DatosPersonales';
// import DatosProfesional from 'components/bajaFallecimiento/DatosProfesional';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { DatosPersonales, DatosProfesional, ArchivosAdjuntos, DatosActaDefuncion } from 'components/bajaFallecimiento';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Tab, Grid, Box, Paper, Link } from '@mui/material';
import { TabList, TabPanel, TabContext } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2';
import { matriculasList, getMatriculasAsync } from 'app/features/Matriculas/matriculasSlice';

import { obrasSocialesList, getObrasSocialesAsync } from 'app/features/TiposObrasSociales/tiposObrasSocialesSlice';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';

import { EMPTY_FILE } from 'constants/icons';

// Styles
import 'scss/components/header.scss';
import { isValid } from 'date-fns';
import { ESTADOS_TRAMITE, TIPOS_USUARIO } from 'constants/constants';
import { ChatBajaFallecimiento } from 'components/chat/chatBajaFallecimiento';

const useStyles = makeStyles(theme => ({
    select: {
        width: '100%',
        backgroundColor: 'red'
    },
    ocultarBotonesContinuar: {
        '@media (min-width:780px)': {
            display: 'none'
        }
    },
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    }

}));

const libraries = ['places'];

const BajaPorFallecimiento = () => {
    const dispatch = useDispatch();
    const clases = useStyles();
    const {pathname} = useLocation();
    const [tabSelectedIndex, setTabSelected] = useState('1');
    const [Matriculas, setMatriculas] = useState([]);
    const [bloquearBtn, setBloquearBtn] = useState(false);
    const [mostrarDatos, setMostrarDatos] = useState(false);
    const [guardarDatosTemporales, setGuardarDatosTemporales] = useState(0);
    const [ocultarContenido, setOcultarContenido] = useState(false);
    const ObrasSociales = useSelector(obrasSocialesList);
    const [idEstado, setIdEstado] = useState(1);
    const [imprimirResultado, setImprimirResultado] = useState(0);
    const [ocultarTabs, setOcultarTabs] = useState({ display: '' });
    const [cantidadTramites, setCantidadTramites] = useState(0);
    const [loaderBtnGuardar, setLoaderBtnGuardar] = useState(false);
    const [tpoUser, setTpoUser] = useState(false);
    const userType = Number(localStorage.userType);

    const adjuntosUsuarioExterno = [
        { fileType: 'DNI del Solicitante - Anverso', fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true },
        { fileType: 'DNI del Solicitante - Reverso', fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true },
        { fileType: 'Acta de Defunción emitida por el registro pertinente', fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true },
        { fileType: 'Otros Documentos', fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true }
    ];

    const adjuntosUsuarioInterno = [
        { fileType: 'Acta de Defunción emitida por el registro pertinente', fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true },
        { fileType: 'Nota de Elevación/ Informe SINTyS', fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true },
        { fileType: 'Otros Documentos', fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true }
    ];

    const [datos, setDatos] = useState({
        Nombre: '',
        Apellido: '',
        NroDocumento: '',
        TipoDocumento: '',
        CuitCuil: '',
        Observado: false,
        PerteneceOtroConsejo: null,
        Nacionalidad: '',
        Email: localStorage.email,
        Celular: '',
        bajaFallecimiento: [
            { LegProf: '0', Nombre: '0', Apellido: '0', codParentesco: '', FecFallecido: '',TipoDocumento: 0, NroDocumento: '', Titulo: '', Tomo: 0 , Folio: 0, FecActa: '', NroActa: null, ActaTomo: null, ActaFolio: null, ActaAnio:null, ActaJurisdiccion: '', Sexo: '', TitulosFallecido: [] }
        ],
        titulos: [],
        domicilios: [],
        adjuntos: userType === TIPOS_USUARIO.USUARIO_EXTERNO ? adjuntosUsuarioExterno : userType === TIPOS_USUARIO.USUARIO_INTERNO ? adjuntosUsuarioInterno : [],
    });
    if (imprimirResultado === 0 && Matriculas.codigoError === 0) {
        if (Matriculas.mensaje !== "Legajo no encontrado!" && Matriculas.result.jsonDatos !== null) {

            const datosProfesional = JSON.parse(Matriculas.result.jsonDatos);
            setIdEstado(Matriculas.result.idEstado);
            setTpoUser(Matriculas.result.tpoUsuario);

            if( pathname === '/bajaFallecimiento'){
                if(Matriculas.result.idEstado === ESTADOS_TRAMITE.DRAFT ){
                    Swal.fire({ 
                        title: '', html: 'Se recuerda que antes de comenzar a cargar la solicitud <u><b>deberá poseer en formato JPG, PDF, JPEG o PNG</b></u> los documentos indicados en los requisitos de baja por fallecimiento detallados en el siguiente link:<br /> <a href="https://www.cpba.com.ar/component/k2/item/8693" target="_blank">clic aquí</a>',
                        icon: 'warning', showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
                    });
                }
            }

            if (Matriculas.result.idEstado === ESTADOS_TRAMITE.PENDIENTE || Matriculas.result.idEstado === ESTADOS_TRAMITE.FINALIZADA ||  (Matriculas.result.idEstado === 6 && !datosProfesional.Observado) || localStorage.userType === "1") {
                setBloquearBtn(true);
            }

            let arrFiles = [];
            let analiticoTitulo = 0;

            let validFileTypes;
            if (userType === 11) {
                validFileTypes = [
                    'DNI del Solicitante - Anverso',
                    'DNI del Solicitante - Reverso',
                    'Acta de Defunción emitida por el registro pertinente',
                    'Otros Documentos',
                    'Declaración Jurada',
                    'Informe del SINTyS'
                ];
            } 
             if (userType === 12) {
                validFileTypes = [
                    'Acta de Defunción emitida por el registro pertinente',
                    'Nota de Elevación/ Informe SINTyS',
                    'Otros Documentos',
                    'Declaración Jurada',
                    'Informe del SINTyS'
                ];
            } 
            if(userType === 1) {
                validFileTypes = [
                    'Acta de Defunción emitida por el registro pertinente',
                    'Otros Documentos',
                    'DNI del Solicitante - Anverso',
                    'DNI del Solicitante - Reverso',
                    'Acta de Defunción emitida por el registro pertinente',
                    'Nota de Elevación/ Informe SINTyS',
                    'Otros Documentos',
                    'Declaración Jurada',
                    'Informe del SINTyS'
                ];
            }

            if (Array.isArray(datosProfesional.adjuntos)) {
                datosProfesional.adjuntos.map((adj) => {
                    if (validFileTypes.includes(adj.fileType.replace(/\d/g, "").trim())) {
                        arrFiles.push({
                            fileType: adj.fileType,
                            fileName: adj.fileName,
                            extension: adj.extension,
                            file: adj.file !== null ? adj.file : '',
                            size: adj.size,
                            url: adj.url === "" ? EMPTY_FILE : adj.url,
                            saved: true
                        });
                    }
                });
            } else {
                arrFiles = datos.adjuntos;
            }
            
            setDatos({
                ...datos,
                Nombre: datosProfesional.Nombre,
                Apellido: datosProfesional.Apellido,
                PerteneceOtroConsejo: datosProfesional.PerteneceOtroConsejo === undefined ? null : datosProfesional.PerteneceOtroConsejo,
                Nacimiento: datosProfesional.Nacimiento,
                NroDocumento: datosProfesional.NroDocumento,
                ObraSocial:datosProfesional.ObraSocial !== undefined && datosProfesional.ObraSocial !== null ? datosProfesional.ObraSocial : '',
                sexo: datosProfesional.sexo ? datosProfesional.sexo : 0,
                EstadoCivil: datosProfesional.EstadoCivil ? datosProfesional.EstadoCivil : '',
                TpoOsocial: datosProfesional.TpoOsocial,
                TipoDocumento: datosProfesional.TipoDocumento,
                CuitCuil: datosProfesional.CuitCuil,
                Observado: datosProfesional.Observado !== undefined && datosProfesional.Observado !== null ? datosProfesional.Observado: false,
                Nacionalidad: datosProfesional.Nacionalidad ? datosProfesional.Nacionalidad : '',
                Email: datosProfesional.Email ? datosProfesional.Email : localStorage.email,
                adjuntos: arrFiles,
                Celular: datosProfesional.Celular ? datosProfesional.Celular : '',
                bajaFallecimiento: datosProfesional.bajaFallecimiento ? datosProfesional.bajaFallecimiento : [{ LegProf: '0', Nombre: '0', Apellido: '0', codParentesco: '', FecFallecido: '',TipoDocumento: 0, NroDocumento: '', Titulo: '', Tomo: 0 , Folio: 0, FecActa: '', NroActa: null, ActaTomo: null, ActaFolio: null, ActaAnio:null, ActaJurisdiccion: '', Sexo: '', TitulosFallecido: [] }],
                CodEnt: datosProfesional.CodEnt ? datosProfesional.CodEnt : null
            })

            setTimeout(() => setMostrarDatos(true), 1000);
            setImprimirResultado(1);
        } else if (Matriculas.codigoError !== null && imprimirResultado === 0) {
            if( pathname === '/bajaFallecimiento'){
                Swal.fire({ 
                    title: '', html: 'Se recuerda que antes de comenzar a cargar la solicitud <u><b>deberá poseer en formato JPG, PDF, JPEG o PNG</b></u> los documentos indicados en los requisitos de baja por fallecimiento detallados en el siguiente link:<br /> <a href="https://www.cpba.com.ar/component/k2/item/13820" target="_blank">clic aquí</a>',
                    icon: 'warning', showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
                });
            }
            setImprimirResultado(1);
            setTimeout(() => setMostrarDatos(true), 1000);
        }

    }

    const handleChange = (event, newValue) => {
        if(tabSelectedIndex === "1"){
            // const nacimientoValido = isValid(new Date(datos.Nacimiento));
            const nombreValido = datos.Nombre !== "";
            const apellidoValido = datos.Apellido !== "";
            const tipoDocumentoValido = datos.TipoDocumento !== "";
            const nroDocumentoValido = datos.NroDocumento !== "";
            // const sexoValido = datos.sexo >= 0;
            // const estadoCivilValido = datos.EstadoCivil !== "";
            const nacionalidadValido = datos.Nacionalidad !== "";
            const cuitValido = datos.CuitCuil !== "";
            // const obraSocialValido = datos.ObraSocial !== "";
            const celularValido = datos.Celular !== "";
            const relacionParentescoValido = datos.bajaFallecimiento[0].codParentesco !== 0;

            const arrValidacion = [
                // {campo: "Fecha de Nacimiento", valido: nacimientoValido}, 
                {campo: "Nombre", valido: nombreValido},
                {campo: "Apellido", valido: apellidoValido},
                {campo: "Tipo de Documento", valido: tipoDocumentoValido},
                {campo: "Número de Documento", valido: nroDocumentoValido},
                // {campo: "Sexo", valido: sexoValido},
                // {campo: "Estado Civil", valido: estadoCivilValido},
                {campo: "Nacionalidad", valido: nacionalidadValido},
                {campo: "CUIT", valido: cuitValido},
                // {campo: "Obra Social", valido: obraSocialValido}
                {campo: "Celular", valido: celularValido},
                // {campo: "Parentesco", valido: relacionParentescoValido}
            ];

            if(userType !== TIPOS_USUARIO.USUARIO_INTERNO && userType !== TIPOS_USUARIO.SECRETARIO_TECNICO && idEstado != 2 && arrValidacion.map(a => a.valido).includes(false)){

                const camposInvalidos = arrValidacion.filter(a => a.valido === false).map(a => a.campo).join(", ");
                Swal.fire({ title: '', html: 'Los siguientes campos son obligatorios: <br><b>' + camposInvalidos + '</b>', 
                    icon: 'warning', showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', 
                    cancelButtonText: 'Cancelar', allowOutsideClick: true});
            }else{
                setTabSelected(newValue);
            }
        }else{
            setTabSelected(newValue);
        }
    };
    
    if (localStorage.token === "") {
        window.location.href = `/loginBajaFallecimiento`;
    }

    const cambiarTab = (tabNuevo) => {
        if (tabNuevo === 1 && tabSelectedIndex !== "1") {
            let nuevoValor = String(Number(tabSelectedIndex) - 1);
            setTabSelected(nuevoValor);
        } else if (tabNuevo === 2 && tabSelectedIndex !== "6") {
            let nuevoValor = String(Number(tabSelectedIndex) + 1);
            setTabSelected(nuevoValor);
        }
    }

    useEffect(() => {
        if (Matriculas.length === 0) {
            let Null = null;
            dispatch(getMatriculasAsync({ Null, Null })).then(valor => {
                setMatriculas(valor.payload)
                // localStorage.setItem('usuario', `${valor.payload.apellido} ${valor.payload.nombre}`);
            });
        }
    }, [ Matriculas ])
    

    return (
        <Grid container >
            {mostrarDatos ?
                <Grid item xs={12} md={11}>
                    <TabContext value={tabSelectedIndex}>
                        <Box >
                            <Grid container style={ocultarTabs} >
                                <Grid item xs={2} className={clases.ocultarBotonesContinuar}>
                                    <Button onClick={() => cambiarTab(1)}><ArrowBackIosIcon /></Button>
                                </Grid>
                                {
                                    ((pathname === '/perfilUsuario' && cantidadTramites > 1) || (cantidadTramites === 1 && idEstado !== 1) || (cantidadTramites >= 2)) && 
                                    <Grid item xs={12}>
                                        <Link style={{ textAlign: 'left', textDecoration: 'underline', cursor: 'pointer' }} ml={2} onClick={() => window.location.href = '/menuBajaFallecimiento'}>
                                            Volver a la lista
                                        </Link>
                                    </Grid> 
                                }
                                <Grid item md={12} xs={9}>
                                    {
                                        (pathname === "/perfilUsuario") ? 
                                        <TabList onChange={handleChange} >
                                            <Tab label={<b>Datos Personales del Solicitante</b>} value="1" style={{fontWeight: 'bold', background: tabSelectedIndex === "1" ? '#E1E1E1': '' , borderRadius: '5px'}}/>
                                        </TabList>
                                        :
                                        (Number(localStorage.userType) === TIPOS_USUARIO.SECRETARIO_TECNICO) ?
                                            <TabList onChange={handleChange} >
                                                <Tab label={<b>Datos Personales del Solicitante</b>} value="1" style={{fontWeight: 'bold', background: tabSelectedIndex === "1" ? '#E1E1E1': '' , borderRadius: '5px'}}/>
                                                <Tab label={<b>Datos del Profesional Fallecido</b>} value="2" style={{fontWeight: 'bold', background: tabSelectedIndex === "2" ? '#E1E1E1': '' , borderRadius: '5px'}}/>
                                                <Tab label={<b>Documentación a Adjuntar</b>} value="3" style={{fontWeight: 'bold', background: tabSelectedIndex === "3" ? '#E1E1E1': '' , borderRadius: '5px'}}/>
                                                <Tab label={<b>Datos Acta de Defunción</b>} value="4" style={{fontWeight: 'bold', background: tabSelectedIndex === "4" ? '#E1E1E1': '' , borderRadius: '5px'}}/>
                                            </TabList>
                                            :
                                            <TabList onChange={handleChange} >
                                                <Tab label={<b>Datos del Profesional Fallecido</b>} value="1" style={{fontWeight: 'bold', background: tabSelectedIndex === "1" ? '#E1E1E1': '' , borderRadius: '5px'}}/>
                                                <Tab label={<b>Documentación a Adjuntar</b>} value="2" style={{fontWeight: 'bold', background: tabSelectedIndex === "2" ? '#E1E1E1': '' , borderRadius: '5px'}}/>                                        
                                            </TabList>
                                    }
                                </Grid>
                                <Grid item xs={1} className={clases.ocultarBotonesContinuar}>
                                    <Button onClick={() => cambiarTab(2)}><ArrowForwardIosIcon /></Button>
                                </Grid>
                            </Grid>
                        </Box>
                        {
                            pathname === "/perfilUsuario" ? 
                            <Paper>
                                <TabPanel value="1">
                                    <DatosPersonales datos={datos} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} setDatos={setDatos} setValue={setTabSelected} ObrasSociales={ObrasSociales} idEstado={idEstado} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} setMatriculas={setMatriculas} setCantidadTramites={setCantidadTramites} tpoUser={tpoUser}/>
                                </TabPanel>
                            </Paper>
                            :
                            (Number(localStorage.userType) === TIPOS_USUARIO.SECRETARIO_TECNICO) ?
                                <Paper>
                                    <TabPanel value="1">
                                        <DatosPersonales datos={datos} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} setDatos={setDatos} setValue={setTabSelected} ObrasSociales={ObrasSociales} idEstado={idEstado} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} setMatriculas={setMatriculas} setCantidadTramites={setCantidadTramites} tpoUser={tpoUser}/>
                                    </TabPanel>
                                    <TabPanel value="2"  >
                                        <DatosProfesional datos={datos} setDatos={setDatos} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} setValue={setTabSelected} ObrasSociales={ObrasSociales} idEstado={idEstado} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} setMatriculas={setMatriculas} setCantidadTramites={setCantidadTramites} tpoUser={tpoUser}/>
                                    </TabPanel>
                                    <TabPanel value="3" >
                                        
                                        <ArchivosAdjuntos datos={datos} setDatos={setDatos} titulosProfesional={[]} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} setValue={setTabSelected} setOcultarTabs={setOcultarTabs} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} idEstado={idEstado} setIdEstado={setIdEstado}  ocultarContenido={ocultarContenido} setOcultarContenido={setOcultarContenido} ValorTab={setTabSelected} setMatriculas={setMatriculas} tpoUser={tpoUser}/>
                                    </TabPanel>
                                    <TabPanel value="4" >
                                        <DatosActaDefuncion datos={datos} setDatos={setDatos} titulosProfesional={[]} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} setValue={setTabSelected} setOcultarTabs={setOcultarTabs} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} idEstado={idEstado} setIdEstado={setIdEstado}  ocultarContenido={ocultarContenido} setOcultarContenido={setOcultarContenido} ValorTab={setTabSelected} setMatriculas={setMatriculas} />
                                    </TabPanel>
                                </Paper>
                                :
                                <Paper>
                                    <TabPanel value="1"  >
                                        <DatosProfesional datos={datos} setDatos={setDatos} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} setValue={setTabSelected} ObrasSociales={ObrasSociales} idEstado={idEstado} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} setMatriculas={setMatriculas} setCantidadTramites={setCantidadTramites} tpoUser={tpoUser}/>
                                    </TabPanel>
                                    <TabPanel value="2" >
                                        <ArchivosAdjuntos datos={datos} setDatos={setDatos} titulosProfesional={[]} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} setValue={setTabSelected} setOcultarTabs={setOcultarTabs} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} idEstado={idEstado} setIdEstado={setIdEstado}  ocultarContenido={ocultarContenido} setOcultarContenido={setOcultarContenido} ValorTab={setTabSelected} setMatriculas={setMatriculas} tpoUser={tpoUser}/>
                                    </TabPanel>
                                </Paper>
                        }           
                    </TabContext>
                </Grid>
                :
                <Grid item xs={12}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                        <CircularProgress />
                    </div>
                </Grid>
            }
            {(
                // (idEstado !== 1 &&  idEstado !== 7 && localStorage.userType !== "0")  ||
                (localStorage.userType !== "0" && datos.Observado === true  && idEstado !== 7) || (localStorage.userType === "1" && idEstado !== 7))
                && 
                <ChatBajaFallecimiento datos={datos} setDatos={setDatos} setIdEstado={setIdEstado}/>} 
        </Grid>
    );
}

export default BajaPorFallecimiento;