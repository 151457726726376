import axios from "axios";
import {CREAR_NUEVO_TRAMITE, HEADERS, HEADERS_TOKEN, INSERTAR_FALLECIMIENTO, NOTIFICAR_VERIFICACION_ACTA_DEFUNCION, OBTENER_GERENCIA, OBTENER_ID_PERFIL_USUARIO, OBTENER_LISTA_TRAMITES, OBTENER_PROF_CON_TITULO, OBTENER_PROF_POR_DOCUMENTO, OBTENER_TOKEN_BAJA, VERIFICAR_PERFIL_USUARIO, VERIFICAR_SI_TIENE_TRAMITE_FALLECIDO } from "constants/routes/endpoints";

export const obtenerListaTramites = async() => {
	const tpoTramite = localStorage.tpoTramite
	const email = localStorage.email
	try {
		let resp = await axios.get(`${OBTENER_LISTA_TRAMITES}/${tpoTramite}/${email}`, {headers: HEADERS});
		resp = JSON.parse(resp.data);

		if (resp.hasOwnProperty('$values')) {
			return resp.$values;
		}
		return resp
	} catch (error) {
		console.log(error)
		return false;
	}
}

export const obtenerProfesionalPorDocumento = async(numeroDocumento) => {
	
	try {
		let resp = await axios.get(`${OBTENER_PROF_POR_DOCUMENTO}/${numeroDocumento}/`, {headers: HEADERS_TOKEN});
		resp = JSON.parse(resp.data);
		return resp;
	} catch (error) {
		console.log(error)
		return false;
	}
}

export const obtenerProfConTitulos = async(legProf) => {
	
	try {
		let resp = await axios.get(`${OBTENER_PROF_CON_TITULO}/${legProf}/`, {headers: HEADERS_TOKEN});
		resp = JSON.parse(resp.data);
		return resp;
	} catch (error) {
		console.log(error)
		return false;
	}
}


export const crearNuevoTramite = async(body) => {
    try {
        let resp = await axios.post(`${CREAR_NUEVO_TRAMITE}`, body, {headers: HEADERS});
        resp = JSON.parse(resp.data);
        return resp;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const obtenerTokenBajaFallecimiento = async(idLegajoTemporal) => {
	try {
		let resp = await axios.get(`${OBTENER_TOKEN_BAJA}/${idLegajoTemporal}`, {headers: HEADERS});
		resp = JSON.parse(resp.data);
		return resp;
	} catch (error) {
		console.log(error)
		return false;
	}
}

export const verificarPerfilUsuario = async(email) => {
	try {
		let resp = await axios.get(`${VERIFICAR_PERFIL_USUARIO}/${email}`, {headers: HEADERS});
		resp = JSON.parse(resp.data);
		return resp;
	} catch (error) {
		console.log(error)
		return false;
	}
}

export const obtnerIdPerfilUsuario = async(email) => {
	try {
		let resp = await axios.get(`${OBTENER_ID_PERFIL_USUARIO}/${email}`, {headers: HEADERS});
		resp = JSON.parse(resp.data);
		return resp;
	} catch (error) {
		console.log(error)
		return false;
	}
}

export const obtenerGerencia = async() => {
	const idLegajoTemporal = localStorage.idLegajoTemporal
	try {
		let resp = await axios.get(`${OBTENER_GERENCIA}/${idLegajoTemporal}`, {headers: HEADERS_TOKEN});
		resp = JSON.parse(resp.data);
		return resp;
	} catch (error) {
		console.log(error)
		return false;
	}
}

export const verificarSiTieneTramiteFallecido = async(legprof) => {
	const idLegajoTemporal = localStorage.idLegajoTemporal
	try {
		let resp = await axios.get(`${VERIFICAR_SI_TIENE_TRAMITE_FALLECIDO}/${idLegajoTemporal}/${legprof}`, {headers: HEADERS_TOKEN});
		resp = JSON.parse(resp.data);
		return resp;
	} catch (error) {
		console.log(error)
		return false;
	}
}

export const insertarFallecimiento = (data, blnPermiteSeguir = false, message = '') => {

    return new Promise((resolve, reject) => {
        const requestData = {
            usuario: encodeURIComponent(localStorage.usuarioValidarDatos),
            password: encodeURIComponent(localStorage.passValidarDatos),
            idLegajoTemporal: Number(localStorage.idLegajoTemporal),
            legProf: 0,
            fecDefuncion: data.fecDefuncion,
            nroActa: data.nroActa,
            nrotomo: data.nrotomo,
            anio: data.anio,
            jurisdiccion: data.jurisdiccion,
            nroFolio: data.nroFolio,
            blnPermiteSeguir,
        };

        if (message !== '') {
            requestData.mensaje = message;
        }

        return axios({
            url: INSERTAR_FALLECIMIENTO,
            method: 'POST',
            headers: HEADERS_TOKEN,
            data: requestData,
        })
            .then(response => response)
            .then(json => {
                return resolve({ data: json.data });
            })
            .catch(err => {
                if (err.response.status === 401 || err.response.status === 500) {
                    window.location.href = `/login`;
                }
                return resolve({ data: err.response.status });
            });
    });
};

export const guardarActaDefuncion = ({ datos, estado, tpo_tramite, accion, fechaAprobacionDocumentacion, nroResolucionAprobacionDocumentacion, tpo_usuario }) => {

	return new Promise((resolve, reject) => {

		const data = {
            idLegajoTemporal: localStorage.idLegajoTemporal,
            idEstado: estado,
            datosTemporales: datos,
            TpoTramite: tpo_tramite,
            accion: accion,
            fechaAprobacionDocumentacion: fechaAprobacionDocumentacion,
            nroResolucionAprobacionDocumentacion: nroResolucionAprobacionDocumentacion,
        };

        if (tpo_usuario === 11) {
            data.tpo_usuario = tpo_usuario;
        }

		return axios({
			url: NOTIFICAR_VERIFICACION_ACTA_DEFUNCION,
			method: 'POST',
			headers: HEADERS_TOKEN,
			data: data
		})
			.then(response => response)
			.then(json => {
				return resolve({ data: json.data });
			})
			.catch(err => {
				var errorJson = err.toJSON();
				console.log(errorJson.config.url);
				console.log(errorJson.message);
				return resolve({ data: {mensaje : 'Por favor vuelva a intentarlo nuevamente'} });
			});
	});
};